import { createSlice } from "@reduxjs/toolkit";
import { getPreDownloadMiddleWare } from "../store/getPreDownloadMiddleWare";

const initialState = {
    loading: false,
    error: "",
    downloaddata: [],
};

const downloadPreReducer = createSlice({
    name: "download",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
        builder.addCase(getPreDownloadMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPreDownloadMiddleWare.fulfilled, (state, action) => {
            state.loading = false;
            state.downloaddata = action.payload;
        });
        builder.addCase(getPreDownloadMiddleWare.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

    },
});

export default downloadPreReducer.reducer;