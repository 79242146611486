import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import SidebarScreen from "./Sidebar";
import './ProtectedLayout.scss';

export default function ProtectedLayout() {
    const location = useLocation();
    // const useAuth = () => {
    //     const user = Cookies.get(TOKEN);
    //     return user && user;
    // };
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getCurrentUser());
    // });
    return (
        <div className="protected__layout__container">
            <div className="protected__layout__header">
               
            </div>
            <div className="protected__layout__content__space">
                <div className="protected__layout__sidebar__container">
                    
                    <SidebarScreen />
                </div>
                <div className="main__content">
                    {/* {useAuth() ? ( */}
                        <Outlet />
                    {/* ) : ( */}
                        {/* <Navigate to={routes.LOGIN} state={{ from: location }} replace /> */}
                    {/* )} */}
                </div>
            </div>
        </div>
    );
}
