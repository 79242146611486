import "./sidebar.scss";
import { Sidebar, Menu, SubMenu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import sideMenu from "./menu";
import { Image } from "primereact/image";

function SidebarScreen() {
  const location = useLocation();

  return (
    <Sidebar className="sidebar__height">
      <Menu>
        <h1 className="title_sidebar">WYF</h1>
        {sideMenu.map((item) => {
          const data = location.pathname;
          const dataArray = data.split('/');
          const includesAwards = dataArray.includes(item.to);
          console.log(includesAwards, "adfds", item.to.split('/')[1])
          const isActive = location.pathname === item.to || dataArray.includes(item.to.split('/')[1]);
          const currentIcon = isActive
            ? item.label.toUpperCase()
            : item.label.toLowerCase();
          const menuItemClassName = `item_text ${isActive ? "active__tab" : ""
            }`;
          return (
            <div
              key={item.label}
              className={`ItemsFlex ${isActive ? "active__tab " : ""}`}
            >
              <SubMenu
                component={<Link to={item.to} />}
                label={
                  <span
                    className={`custom-label ${isActive ? "active__label" : ""
                      }`}
                  >
                    <div className="label-container">{item.label}</div>
                  </span>
                }
              >
                {item.subMenu &&
                  item.subMenu.map((sub) => (
                    <MenuItem component={<Link to={sub.to} key={sub.label} />}>
                      {sub.label}
                    </MenuItem>
                  ))}
              </SubMenu>
            </div>
          );
        })}
      </Menu>
    </Sidebar>
  );
}

export default SidebarScreen;
