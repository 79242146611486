import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../../utility/commonServices";
import {
  DELETE_ACCESS_CONTROL_PARAMETER_BY_ID,
  GET_ACCESS_CONTROL_PARAMETER,
  GET_ALL_ACCESS_CONTROL_PARAMETER,
  PATCH_ACCESS_CONTROL_PARAMETER,
  PATCH_CHANGE_STATUS_ACCESS_CONTROL_PARAMETER,
  POST_ACCESS_CONTROL_PARAMETER_ID,
} from "../../../../redux/actionTypes";
import { APIROUTES } from "../../../../routes/apiRoutes";

export const fetchAllAccessControlParameter = createAsyncThunk(
  GET_ALL_ACCESS_CONTROL_PARAMETER,
  async (_a, { getState, rejectWithValue }) => {
    const { accessControlParameterReducers } = getState();
    try {
      const { data } = await getRequest(
        `${APIROUTES.ACCESSPARAMETER.GET_ALL}?pageNo=1&size=5`
      );
      // tokenHanlder(rememberMe, data.token);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchAccessControlParameterById = createAsyncThunk(
  GET_ACCESS_CONTROL_PARAMETER,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${APIROUTES.ACCESSPARAMETER.GET_BY_ID}/${id}`
      );
      // tokenHanlder(rememberMe, data.token);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const patchAccessControlParameterById = createAsyncThunk(
  PATCH_ACCESS_CONTROL_PARAMETER,
  async (_a, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.ACCESSPARAMETER.PATCH_BY_ID}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);
export const patchAccessControlParameterByStatus = createAsyncThunk(
  PATCH_CHANGE_STATUS_ACCESS_CONTROL_PARAMETER,
  async (_a, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.ACCESSPARAMETER.CHANGE_STATUS}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const deleteAccessControlParameterById = createAsyncThunk(
  DELETE_ACCESS_CONTROL_PARAMETER_BY_ID,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteRequest(
        `${APIROUTES.ACCESSPARAMETER.DELETE_BY_ID}/${id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);
export const createAccessControlParameter = createAsyncThunk(
  POST_ACCESS_CONTROL_PARAMETER_ID,
  async ({}, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${APIROUTES.ACCESSPARAMETER.POST_FORM}`,
        {}
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);
