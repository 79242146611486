import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./PreRegistration.scss";
import { setSkip } from "./store/preRedgistrationReducer";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "../../components/Profile";
import { useDispatch, useSelector } from "react-redux";
import { getPreRegistationMiddleWare } from "./store/preRegistrationMiddleware.";
import Addone from "../../components/Addone";
import PreRegisterSearch from "./PreRegisterSearch";
import PreDownload from "./PreDownload";
import PreEditData from "../PreRegistration/PreEditData";
import Delete from "../../components/Delete";
import "primeicons/primeicons.css";

const PreRegistration = () => {
  const [response, setResponse] = useState("");
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState(false);
  const { state } = useLocation();
  const { id, name } = useParams();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { data, skip, count } = useSelector(({ preRegistrationReducers }) => {
    return {
      loading: preRegistrationReducers?.loading,
      data: preRegistrationReducers?.preRegData,
      skip: preRegistrationReducers?.pageNo,
      count: preRegistrationReducers?.count,
    };
  });
  const handlegetPreRegister = () => {
    dispatch(getPreRegistationMiddleWare({ id }));
  };
  useEffect(() => {
    handlegetPreRegister();
  }, [skip]);
  const paginate = {
    RowsPerPageDropdown: (options) => {
      return (
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Page:{" "}
        </span>
      );
    },
    CurrentPageReport: (options) => {
      const startIndex = parseInt(skip - 1) * 10 + 1;
      const endIndex =
        count > startIndex + 10 - 1 ? startIndex + 10 - 1 : count;

      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {startIndex} - {endIndex} of {count}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
            }}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip + 1));
            }}
          >
            <span className="p-3">Next</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(1));
            }}
          >
            <span className="p-3">First</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(Math.ceil(count / 10)));
            }}
          >
            <span className="p-3">Last</span>
          </button>
        </>
      );
    },
  };

  const home = {
    label: "Event And Program",
    url: "/awards",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/awards/${id}/${name}`,
      className: "breadcrumb-home",
    },
    {
      label: "Registration",
      url: `/awards/registration/${id}/${name}`,
      className: "breadcrumb-home",
    },
    {
      label: "Pre-Registration",
      url: `/awards/preregistration/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];

  return (
    <div>
      <div className="top-row">
        <BreadCrumb home={home} model={items} />
        <Profile />
      </div>
      <div className="inputcontainer">
        <div style={{ width: "80%", display: "flex" }}>
          <Addone id={id} eventDetails={state} dataa={data?.allProjectData} />
          {selectedProducts?.length === 1 && (
            <PreEditData
              data={selectedProducts}
              clear={setSelectedProducts}
              id={id}
            />
          )}
          {selectedProducts?.length > 0 && (
            <Delete
              dataa={selectedProducts}
              clear={setSelectedProducts}
              setResponse={setResponse}
              id={id}
            />
          )}
        </div>
        <div className="search">
          <PreDownload id={id} />
          <PreRegisterSearch id={id} />
        </div>
      </div>

      <div>
        <p className="preReg">Pre Registration</p>
      </div>
      <div className="table-container" tableStyle={{ minWidth: "50rem" }}>
        <DataTable
          value={data?.allProjectData}
          tableStyle={{ minWidth: "50rem" }}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode="checkbox"
          onPage={onPageChange}
          first={first}
          rows={10}
          paginator={true}
          paginatorTemplate={paginate}
        >
          <Column field="select" header="" selectionMode="multiple"></Column>
          <Column field="registrationNo" header="Registration No"></Column>
          <Column field="nameStudent1" header="Team Lead Name"></Column>
          <Column field="projectName" header="Project Title"></Column>
          <Column field="emailStudent1" header="Email ID"></Column>
          <Column field="mobileStudent1" header="Contact"></Column>
          <Column field="teamSize" header="Team Size"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PreRegistration;
