import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_DOWNLOAD } from "../../../redux/actionTypes";

export const getDownloaddataMiddleWare = createAsyncThunk(
    GET_DOWNLOAD,
    async ({ id }, { rejectWithValue }) => {
        console.log(id,"search datas download")
        try {
            const { data } = await getRequest(`${APIROUTES.DOWNLOAD.GET_DOWNLOAD}${id.id}`);
             
            return data;
            
        }
         catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);