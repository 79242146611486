import React, { useEffect } from "react";
import "./Verified.scss";
import { Card } from "primereact/card";
import Profiles from "../../components/Profile";
import { BreadCrumb } from "primereact/breadcrumb";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAwardsMiddleWare } from "../Awards/store/awardsMiddleWare";
function Verified_business_idea() {
  const dispatch = useDispatch();
  const handleGet = () => {
    dispatch(getAwardsMiddleWare({}));
  };
  const { data, loading, count } = useSelector(({ awardsReducers }) => {
    return {
      loading: awardsReducers.isLoading,
      data: awardsReducers.allAwards,
    };
  });

  useEffect(() => {
    handleGet();
  }, []);
  const verified = {
    label: "Event And Program",
    url: "/verifiedbusinessidea",
    className: "breadcrumb-home",
  };
  return (
    <div>
      <BreadCrumb home={verified} />
      <Profiles />
      <div className="overall_container">
        <div className="container"></div>

        <div className="Awards_carditem_container">
          {data.map((cardData) => (
            <Link
              to={`/verifiedbusinessidea/verified_year_details/${cardData?._id}/${cardData?.eventName}`}
            >
              <Card className="carditem">{cardData?.eventName}</Card>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Verified_business_idea;
