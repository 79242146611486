import React, { useState, useEffect } from "react";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";

import { BreadCrumb } from "primereact/breadcrumb";

import { Button } from "primereact/button";

import "./PostRegistration.scss";

import Profile from "../../components/Profile";

import "primeicons/primeicons.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  getPostRegistationMiddleWare,
  getSelectedData,
  getWaitingData,
  getRejectedData,
  getBusinessIdeaData,
  patchUpdateMiddleWare,
} from "./store/postRegisterMiddleware";

import PostSearchInputBox from "./PostSearchInputBox";

import PostDownload from "./PostDownload";

import { setSkip } from "./store/postRegisterReducer";

const PostRegistration = () => {
  const { id, name } = useParams();
  const [selectedProducts, setSelectedProducts] = useState(false);
  const [activeTab, setActiveTab] = useState("postRegister");
  const [showData, setShowData] = useState("postRegister");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [tabData, setTabData] = useState();

  const handlenav = (id) => {
    naviagte(`/postParticpientDeatils/${id}`);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const { data, loading, count, skip } = useSelector(
    ({ postRegistrationReducers }) => {
      console.log(postRegistrationReducers, "sdfghjk");
      return {
        loading: postRegistrationReducers?.loading,
        data: postRegistrationReducers?.postRegData,
        count: postRegistrationReducers?.count,
        skip: postRegistrationReducers?.pageNo,
      };
    }
  );
  const handlegetPostRegister = (id) => {
    console.log(id);

    dispatch(getPostRegistationMiddleWare({ id }));
  };
  const paginate = {
    RowsPerPageDropdown: (options) => {
      return (
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Page:{" "}
        </span>
      );
    },

    CurrentPageReport: (options) => {
      const startIndex = parseInt(skip - 1) * 10 + 1;

      const endIndex =
        count > startIndex + 10 - 1 ? startIndex + 10 - 1 : count;

      console.log(startIndex);

      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {startIndex} - {endIndex} of {count}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
            }}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip + 1));
            }}
          >
            <span className="p-3">Next</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(1));
            }}
          >
            <span className="p-3">First</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(Math.ceil(count / 10)));
            }}
          >
            <span className="p-3">Last</span>
          </button>
        </>
      );
    },
  };
  const renderDataTable = (dataKey) => {
    return (
      <DataTable
        value={data?.allProjectData}
        showGridlines
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        selectionMode="checkbox"
        onRowClick={
          selectedProducts == false
            ? (event) => handlenav(event.data._id)
            : null
        }
        onPage={onPageChange}
        first={first}
        rows={10}
        paginator={true}
        paginatorTemplate={paginate}
      >
        {activeTab == "postRegister" ? null : (
          <Column field="select" header="" selectionMode="multiple"></Column>
        )}
        <Column field="registrationNo" header="Registration No"></Column>
        <Column field="nameStudent1" header="Team Lead Name"></Column>
        <Column field="projectName" header="Project Title"></Column>
        <Column field="emailStudent1" header="Email ID"></Column>
        <Column field="mobileStudent1" header="Contact"></Column>
        <Column field="teamSize" header="Team Size"></Column>
        {activeTab == "postRegister" ? null : (
          <Column field="selectedBy" header="Selected By"></Column>
        )}
      </DataTable>
    );
  };
  const handleTableData = (tab) => {
    if (tab == "postRegister") {
      dispatch(getPostRegistationMiddleWare({ id }));
    } else if (tab == "Selected") {
      dispatch(getSelectedData({ id }));
    } else if (tab == "Waiting") {
      dispatch(getWaitingData({ id }));
    } else if (tab == "Rejected") {
      dispatch(getRejectedData({ id }));
    } else if (tab == "BussinessIdea") {
      dispatch(getBusinessIdeaData({ id }));
    }
  };

  const handleShowData = (tab) => {
    if (tab == "postRegister") {
      dispatch(getPostRegistationMiddleWare({ id }));
      dispatch(setSkip(1));
    } else if (tab == "Selected") {
      dispatch(getSelectedData({ id }));
      dispatch(setSkip(1));
    } else if (tab == "Waiting") {
      dispatch(getWaitingData({ id }));
      dispatch(setSkip(1));
    } else if (tab == "Rejected") {
      dispatch(getRejectedData({ id }));
      dispatch(setSkip(1));
    } else if (tab == "BussinessIdea") {
      dispatch(getBusinessIdeaData({ id }));
      dispatch(setSkip(1));
    }
    console.log(tab);
    setActiveTab(tab);
    setTabData(tab);
  };

  useEffect(() => {
    handleTableData(activeTab);
  }, [skip]);

  useEffect(() => {
    handleShowData(activeTab);
  }, [activeTab]);

  const handleData = (tab) => {
    if (tab == "postRegister") {
      dispatch(getPostRegistationMiddleWare({ id }));
      dispatch(setSkip(1));
    } else if (tab == "Selected") {
      dispatch(getSelectedData({ id }));
      dispatch(setSkip(1));
      if (selectedProducts) {
        let arr = [];
        selectedProducts?.map((data) => {
          arr.push(data?._id);
        });
        dispatch(
          patchUpdateMiddleWare({
            projectStatus: "selected", // update only  to selected
            projectIdList: arr,
          })
        );
      }
    } else if (tab == "Waiting") {
      dispatch(getWaitingData({ id }));
      dispatch(setSkip(1));
      if (selectedProducts) {
        let arr = [];
        selectedProducts?.map((data) => {
          arr.push(data?._id);
        });
        dispatch(
          patchUpdateMiddleWare({
            projectStatus: "waiting", // update only  to waiting
            projectIdList: arr,
          })
        );
      }
    } else if (tab == "Rejected") {
      dispatch(getRejectedData({ id }));
      dispatch(setSkip(1));
      if (selectedProducts) {
        let arr = [];
        selectedProducts?.map((data) => {
          arr.push(data?._id);
        });
        dispatch(
          patchUpdateMiddleWare({
            projectStatus: "rejected", // update only  to rejected
            projectIdList: arr,
          })
        );
      }
    } else if (tab == "BussinessIdea") {
      dispatch(getBusinessIdeaData({ id }));
      dispatch(setSkip(1));
      if (selectedProducts) {
        let arr = [];
        selectedProducts?.map((data) => {
          arr.push(data?._id);
        });
        dispatch(
          patchUpdateMiddleWare({
            projectStatus: "businessidea", // update only  to businessidea
            projectIdList: arr,
          })
        );
      }
    }
    setActiveTab(tab);
    setTabData(tab);
  };

  useEffect(() => {
    handleTableData(activeTab);
  }, [skip]);
  useEffect(() => {
    handleShowData(activeTab);
  }, [activeTab]);

  const home = {
    label: "Event And Program",
    url: "/awards",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/awards/${id}/${name}`,
      className: "breadcrumb-home",
    },
    {
      label: "Registration",
      url: `/awards/registration/${id}/${name}`,
      className: "breadcrumb-home",
    },
    {
      label: "Post-Registration",
      url: `/awards/postregistration/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];

  return (
    <div>
      <div className="top-row">
        <div class="grid">
          <div class="col">
            <BreadCrumb home={home} model={items} />
          </div>
        </div>

        <Profile />
      </div>

      <div className="subContainer">
        <div>
          {selectedProducts == "" || activeTab == "postRegister" ? null : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="moveText">Move To </p>
              <i
                className="pi pi-arrow-right"
                style={{
                  marginRight: 10,

                  color: "#0B1B6E",

                  marginTop: 5,
                }}
              ></i>

              {["Selected", "Waiting", "Rejected", "BussinessIdea"].map(
                (tab) =>
                  tab !== activeTab && (
                    <Button
                      key={tab}
                      className={`btns ${activeTab === tab ? "active" : ""}`}
                      onClick={() => handleData(tab)}
                    >
                      <p style={{ color: "#0D1E77" }}>{tab}</p>
                    </Button>
                  )
              )}
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",

            alignItems: "flex-end",

            justifyContent: "flex-end",

            alignSelf: "flex-end",
          }}
        >
          <div style={{ display: "flex" }}>
            <PostDownload id={id} tabData={tabData} />
            <PostSearchInputBox
              id={id}
              activeTab={activeTab}
              tabData={tabData}
            />
          </div>
        </div>
      </div>

      <div className="inputcontainer"></div>

      <div className="buttons" class="grid">
        <div class="col">
          <Button
            onClick={() => handleShowData("postRegister")}
            style={{
              backgroundColor:
                activeTab == "postRegister" ? "#D26868" : "#FFEFE1",

              color: activeTab == "postRegister" ? "#fff" : "#f16969",
              border: "none",
            }}
            className="postReg"

            // className={`postReg ${activeTab === 'postRegister' && 'active'}`}
          >
            Post Registration
          </Button>
          <div
            style={{
              paddingLeft: "10%",
              width: "90%",
            }}
          >
            <div
              style={{
                backgroundColor: activeTab == "postRegister" ? "#D26868" : null,
                height: 3,
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <div class="col">
          <Button
            onClick={() => handleShowData("Selected")}
            style={{
              backgroundColor: activeTab == "Selected" ? "#49C846" : "#E2FFE1",

              color: activeTab == "Selected" ? "#fff" : "#49C846",
            }}
            className="select"
          >
            Selected
          </Button>
          <div
            style={{
              width: "95%",
              paddingLeft: "15%",
            }}
          >
            <div
              style={{
                backgroundColor: activeTab == "Selected" ? "#49C846" : null,
                height: 3,
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <div class="col">
          <Button
            onClick={() => handleShowData("Waiting")}
            style={{
              backgroundColor: activeTab == "Waiting" ? "#EFCB4F" : "#FFF5D2",

              color: activeTab == "Waiting" ? "#fff" : "#EFCB4F",
            }}
            className="waiting"
          >
            Waiting
          </Button>
          <div
            style={{
              width: "95%",
              paddingLeft: "15%",
            }}
          >
            <div
              style={{
                backgroundColor: activeTab == "Waiting" ? "#EFCB4F" : null,
                height: 3,
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <div class="col">
          <Button
            onClick={() => handleShowData("Rejected")}
            style={{
              backgroundColor: activeTab == "Rejected" ? "#FF6565" : "#FFE8E8",

              color: activeTab == "Rejected" ? "#fff" : "#FF6565",
            }}
            className="rejecte"
          >
            Rejected
          </Button>
          <div
            style={{
              width: "95%",
              paddingLeft: "15%",
            }}
          >
            <div
              style={{
                backgroundColor: activeTab == "Rejected" ? "#FF6565" : null,
                height: 3,
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <div class="col">
          <Button
            onClick={() => handleShowData("BussinessIdea")}
            style={{
              backgroundColor:
                activeTab == "BussinessIdea" ? "#0D1E77" : "#a5add4",

              color: activeTab == "BussinessIdea" ? "#fff" : "#0D1E77",
            }}
            className="bussiness"
          >
            Business Idea
          </Button>
          <div
            style={{
              width: "95%",
              paddingLeft: "15%",
            }}
          >
            <div
              style={{
                backgroundColor:
                  activeTab == "BussinessIdea" ? "#0D1E77" : null,
                height: 3,
                width: "100%",
              }}
            ></div>
          </div>
        </div>
      </div>

      {showData && renderDataTable(showData)}
    </div>
  );
};

export default PostRegistration;
