import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_ROLLACCESSEARCH } from "../../../redux/actionTypes";

export const getrollaccesSearchMiddleWare = createAsyncThunk(
    GET_ROLLACCESSEARCH,
    async ({ search}, { rejectWithValue }) => {
        console.log( search,"search datas>>2")
        try {
            const { data } = await getRequest(`${APIROUTES.SEARCHDATA.GET_ROLLACCESSEARCH}${search}`);
            
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);