import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import { GET_ALL_AWARDS, POST_AWARDS } from "../../../redux/actionTypes";

export const getAwardsMiddleWare = createAsyncThunk(

  GET_ALL_AWARDS,
  async (_a, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(APIROUTES.AWARDS.GET_ALL_AWARD);
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const postNewAward = createAsyncThunk(
  POST_AWARDS,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        APIROUTES.AWARDS.CREATE_NEW_AWARD,
        payload
      );
      console.log("postData", data);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }

);
