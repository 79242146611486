import React from "react";
import "./index.scss";
import { Splitter, SplitterPanel } from "primereact/splitter";
import Leftindex from "./Leftindex";
import Rightindex from "./Rightindex";

const index = () => {
  return (
    <Splitter class="grid" style={{ height: "101vh" }}>
      <SplitterPanel className="leftcontainer">{<Leftindex />}</SplitterPanel>
      <SplitterPanel className="rightcontainer">{<Rightindex />}</SplitterPanel>
    </Splitter>
  );
};

export default index;
