import authReducers from '../module/Login/store/loginReducer';
import accessControlParameterReducers from '../module/TableView/AccessControlParameter/store/accessControlParameterReducer';
import awardsReducers from '../module/Awards/store/awardsReducer';
import profileReducers from '../components/auth/authReducer';
import roleAccessReducers from '../module/RoleAccess/store/roleaccessReducer';
import eventReducers from '../module/EventAward/store/eventAwardReducer';
import preRegistrationReducers from '../module/PreRegistration/store/preRedgistrationReducer';
import postRegistrationReducers from '../module/PostRegistration/store/postRegisterReducer';
import particepentDetailsReducers from '../module/ParticipentDetails/store/participentDetailsReducer'
import getRoleMasterReducers from '../components/auth/store/roleMasterReducer'
import getverifiedtableReducers from '../module/Verified_business_idea/store/verifiedReducer'
import getsearchDataReducer from '../components/auth/store_search/searchReducer';
import getdownloadReducer from '../components/auth/download_store/downloadReducer'
// import getverifiedtableReducers from '../module/Verified_business_idea/store/verifiedReducer';
import getSearchPre from '../module/PreRegistration/store/SearchPreReducer';
import getDownloadPre from '../module/PreRegistration/store/downloadPreReducer';
import postGetSearch from '../module/PostRegistration/store/searchPostReducer'
import RollaccessearchReducers from '../components/auth/store_rollaccess/rollaccessReducer'
import patchDeleteverifiedReducers from '../components/auth/store_delete/deleteVerifiedReducer';
import postParticipentDetails from '../module/PostRegistration/store/postRegisterReducer'
const reducers = {
    authReducers,
    accessControlParameterReducers,
    awardsReducers,
    profileReducers,
    roleAccessReducers,
    eventReducers,
    preRegistrationReducers,
    postRegistrationReducers,
    particepentDetailsReducers,
    getRoleMasterReducers,
    getsearchDataReducer,
    getverifiedtableReducers,
    getdownloadReducer,
    getverifiedtableReducers,
    getSearchPre,
    getDownloadPre,
    postGetSearch,
    RollaccessearchReducers,
    getdownloadReducer,
    patchDeleteverifiedReducers,
    postParticipentDetails,
    
}
export default reducers;