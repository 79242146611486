import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest, postRequest } from "../../../utility/commonServices";
import { APIROUTES, loginApi } from "../../../routes/apiRoutes";
import { LOGIN } from "../../../redux/actionTypes";


// const tokenHanlder = (rememberMe, token) => {
//     const EXPIRY_DAYS = rememberMe
//         ? REMEMBER_ME_EXPIRY_DAYS
//         : DEFAULT_TOKEN_EXPIRY_DAY;
//     Cookies.set(TOKEN, token, { expires: EXPIRY_DAYS });
// };

export const login = createAsyncThunk(
    LOGIN,
    async (
        { email, password },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await postRequest(APIROUTES.LOGIN.POST_LOGIN, { email, password });
            // tokenHanlder(rememberMe, data.token);
            Cookies.set('token', data.token);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response.data.error.message);
        }
    },
);

export const getCurrentUser = createAsyncThunk(
    "login/getCurrentUser",
    async (_a, { rejectWithValue }) => {
        try {
            const { data } = await getRequest('a');
            return data;
        } catch (error) {
            return rejectWithValue(error?.response.data.error.message);
        }
    },
);
