import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import { GET_VERIFIEDTABLEDATA } from "../../../redux/actionTypes";

export const getVerifieddataMiddleWare = createAsyncThunk(
    GET_VERIFIEDTABLEDATA,
    async ({ id }, { getState, rejectWithValue }) => {
        const { getverifiedtableReducers } = getState()
        try {
            const { data } = await getRequest(`${APIROUTES.VERIFIEDTABLEDATA.GET_VERIFIEDTABLEDATA}${id}/${getverifiedtableReducers.pageNo}`);
            console.log(id, "check id passed")
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
              
            }
            
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);