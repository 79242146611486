import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { postUserMiddleWare } from "../module/RoleAccess/store/roleaccessMiddleware";
import { getRoleMasterMiddleWare } from "./auth/store/rolemasterMiddleWare";
import { getUserMiddleWare } from "../module/RoleAccess/store/roleaccessMiddleware";
function Add({ selectedData }) {
  console.log(selectedData?.role?.role, "=========>selectedData");
  const [visible, setVisible] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [datas, setData] = useState();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...datas,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      postUserMiddleWare({
        name: datas?.name,
        email: datas?.email,
        contactNo: datas?.contactNo,
        role: selectedCity?.name,
      })
    )
      .then((res) => {
        setVisible(false);
        dispatch(getUserMiddleWare({}));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleroleMaster = () => {
    dispatch(getRoleMasterMiddleWare({}));
  };
  const { data, loading } = useSelector(({ getRoleMasterReducers }) => {
    return {
      loading: getRoleMasterReducers?.loading,
      data: getRoleMasterReducers?.rollmaster,
    };
  });
  useEffect(() => {
    handleroleMaster();
  }, []);

  const cities = data?.map((dat) => {
    return {
      name: dat?.role,
    };
  });

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-plus"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 115,
          height: 55,
          margin: 10,
        }}
        label="Add"
      ></Button>
      <Dialog
        visible={visible}
        className="Add_modelcontainer"
        onHide={() => setVisible(false)}
      >
        <div className="inner_container">
          <h4>Name</h4>
          <InputText
            placeholder="Enter Name"
            name="name"
            onChange={handleChange}
            className="inputs_container"
          />

          <h4>Email ID</h4>
          <InputText
            placeholder="Enter Email"
            onChange={handleChange}
            name="email"
            className="inputs_container"
          />

          <h4>Contact Number</h4>
          <InputText
            placeholder="Enter Contact Number"
            onChange={handleChange}
            name="contactNo"
            className="inputs_container"
          />

          <h4>Access</h4>
          <Dropdown
            // name="role"
            value={selectedCity || selectedData?.role?.role}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder={`${selectedData?.role?.role}`}
            // className="inputs_container"
          />
          <Button
            label="Submit"
            className="submit_txt"
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default Add;
