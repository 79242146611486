import { createSlice } from "@reduxjs/toolkit";
import {
  getEventAwardDataMiddleWare,
  postNewEventAwardMiddleware,
} from "./eventAwardMIddleWare";

const initialState = {
  loading: false,
  error: "",
  allAwards: [],
  singleAward: {},
};
const eventAwardReducer = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventAwardDataMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventAwardDataMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.allAwards = action.payload;
    });
    builder.addCase(getEventAwardDataMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.token = "";
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(postNewEventAwardMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postNewEventAwardMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      state.singleAward = action.payload;
    });
    builder.addCase(postNewEventAwardMiddleware.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
  },
});

export default eventAwardReducer.reducer;
