import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_POST_SEARCH_ALL_RECORDS,GET_POST_SELECTED_SEARCH,GET_POST_WAITING_SEARCH ,GET_POST_REJECTED_SEARCH,GET_POST_BUSSINESSIDEA_SEARCH} from "../../../redux/actionTypes";

export const getSearchPostMiddleWare = createAsyncThunk(
    GET_POST_SEARCH_ALL_RECORDS,
    async ({ tabData , search}, { rejectWithValue }) => {
      
        try {
            const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_SEARCH_ALL_RECORDS}/${search}`);
          
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
export const getPostSelectSearch = createAsyncThunk(
    GET_POST_SELECTED_SEARCH,
    async ({ tabData , search}, { rejectWithValue }) => {
       
        try {
            const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_SELECTED_SEARCH}/${search}`);
           
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
export const getPostWaitingSearch = createAsyncThunk(
    GET_POST_WAITING_SEARCH,
    async ({ tabData , search}, { rejectWithValue }) => {

        try {
            const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_WAITING_SEARCH}/${search}`);
          
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
export const getPostRejectedSearch = createAsyncThunk(
    GET_POST_REJECTED_SEARCH,
    async ({ tabData , search}, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_REJECTED_SEARCH}/${search}`);
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
export const getPostBussinessIdeaSearch = createAsyncThunk(
    GET_POST_BUSSINESSIDEA_SEARCH,
    async ({ tabData , search}, { rejectWithValue }) => {
      
        try {
            const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_BUSSINESSIDEA_SEARCH}/${search}`);
           
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
