import { createSlice } from "@reduxjs/toolkit";
import { getAwardsMiddleWare, postNewAward } from "./awardsMiddleWare";

const initialState = {
  loading: false,
  error: "",
  allAwards: [],
  singleAward: {},
};
const awardsReducer = createSlice({

  name: "awards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAwardsMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAwardsMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.allAwards = action.payload;
    });
    builder.addCase(getAwardsMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.token = "";
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(postNewAward.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postNewAward.fulfilled, (state, action) => {
      state.loading = false;
      state.singleAward = action.payload;
    });
    builder.addCase(postNewAward.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
  },

});

export default awardsReducer.reducer;
