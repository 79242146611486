import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_SEARCHDATA } from "../../../redux/actionTypes";

export const getSearchdataMiddleWare = createAsyncThunk(
    GET_SEARCHDATA,
    async ({ id , search}, { rejectWithValue }) => {
        console.log(id, search,"search datas")
        try {
            const { data } = await getRequest(`${APIROUTES.SEARCHDATA.GET_SEARCHDATA}${id}/${search}`);
            
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);