import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "./store/loginMiddleWare";

function Rightindex() {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const updateWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowHeight);

    return () => {
      window.removeEventListener("resize", updateWindowHeight);
    };
  }, []);

  const handleclick = () => {
    dispatch(login({ email, password })).then((res) => {
      if (res?.payload?.role == "Super Admin") {
        if (res.payload?.token) {
          naviagte("/Dashbored");
        }
      } else {
        window.alert("Invalid credentials. Please try again.");
      }
    });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div style={{ height: `${windowHeight}px` }} className="overall_conatiner">
      <div>
        <h1 style={{ color: "white", textAlign: "center" }}>WYF</h1>

        <div className="containers">
          <div>
            <div>
              <h3 style={{ color: "#fff" }}> Email ID</h3>
              <InputText
                placeholder="email"
                className="Inputtext_container"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <h3 style={{ color: "#fff" }}>Password</h3>
              <span className="p-input-icon-right">
                <InputText
                  placeholder="Password"
                  className="Inputtext_container"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </span>
            </div>
            <div>
              <h3 onClick={handleclick} className="Login_txt">
                Login
              </h3>
            </div>
          </div>
        </div>

        <div className="footer_container">
          <div>
            <h3 className="footer_txt">To know more about WYF Visit</h3>
            <h4 style={{ fontSize: 12, textAlign: "center", color: "#6075E4" }}>
              www.wyfonline.org
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rightindex;
