export const BASE_URL = "https://wyf-admin.zealeye.com/api/admin-panel/";
// export const BASE_URL = "http://localhost:3000/api/admin-panel/";
// token string
export const TOKEN = "token";
export const DEFAULT_TOKEN_EXPIRY_DAY = 1;
export const REMEMBER_ME_EXPIRY_DAYS = 365;
export const PaginationDropdown = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
];
