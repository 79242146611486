import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import Profilepage from "../../components/Profile";
import { Column } from "primereact/column";
import "./RoleAccess.scss";

import Edit from "../../components/Edit";

import { InputSwitch } from "primereact/inputswitch";
import Add from "../../components/Add";

import { useNavigate, useParams } from "react-router-dom";
import Rollaccessearch from "../../components/Rollacccessearch";
import Rollaccessdownload from "../../components/Rollaccessdownload";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserMiddleWare,
  patchdeactivateMiddleWare,
} from "./store/roleaccessMiddleware";

import { setSkip } from "./store/roleaccessReducer";
function RoleAccess() {
  const [selectedProducts, setSelectedProducts] = useState(false);
  const [checked, setChecked] = useState(true);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const dispatch = useDispatch();

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handlegetuser = () => {
    dispatch(getUserMiddleWare({}));
  };
  const { data, loading, count, skip } = useSelector(
    ({ roleAccessReducers }) => {
      return {
        loading: roleAccessReducers?.loading,
        data: roleAccessReducers?.rollaccess_user,
        count: roleAccessReducers?.count,
        skip: roleAccessReducers?.pageNo,
      };
    }
  );
  useEffect(() => {
    handlegetuser();
  }, []);

  const handleToggleStatus = (rowData) => {
    const newStatus = !rowData.activeStatus;
    dispatch(
      patchdeactivateMiddleWare({
        id: rowData._id,
        activeStatus: newStatus,
      })
    )
      .then((res) => {
        if (res) {
          dispatch(getUserMiddleWare({}));
        }
      })
      .catch((error) => {});
  };

  const StatusBody = (
    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
  );
  const paginate = {
    RowsPerPageDropdown: (options) => {
      return (
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Page:{" "}
        </span>
      );
    },
    CurrentPageReport: (options) => {
      const startIndex = parseInt(skip - 1) * 10 + 1;
      const endIndex =
        count > startIndex + 10 - 1 ? startIndex + 10 - 1 : count;

      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {startIndex} - {endIndex} of {count}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
            }}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip + 1));
            }}
          >
            <span className="p-3">Next</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(1));
            }}
          >
            <span className="p-3">First</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(Math.ceil(count / 10)));
            }}
          >
            <span className="p-3">Last</span>
          </button>
        </>
      );
    },
  };

  return (
    <div>
      <Profilepage />
      <div className="inputcontainer_roleaccess">
     
          <Add selectedData={selectedProducts[0]} />
        
        {selectedProducts.length == 1 ? (
          <Edit data={selectedProducts[0]} />
        ) : null}
        <div className="roleaccess_container2">
          <Rollaccessdownload />
          <Rollaccessearch />
        </div>
      </div>

      <div className="table-container" tableStyle={{ minWidth: "50rem" }}>
        <DataTable
          value={data?.allUserData}
          tableStyle={{ minWidth: "50rem" }}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode="checkbox"
          onPage={onPageChange}
          first={first}
          rows={10}
        >
          <Column field="select" header="" selectionMode="multiple" />
          <Column field="name" header="Name"></Column>
          <Column field="email" header="Email ID"></Column>
          <Column field="contactNo" header="Contact"></Column>
          <Column field="role.role" header="Access"></Column>

          <Column
            field="activeStatus"
            header="Status"
            body={(rowData) => (
              <InputSwitch
                checked={rowData.activeStatus}
                onChange={() => handleToggleStatus(rowData)}
              />
            )}
            dataType="boolean"
            data="activeStatus"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default RoleAccess;
