import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {
  GET_PRE_REGISTRATION,
  POST_CREATE_NEW_PROJECT,
  POST_UPLOAD_FILE,
  PATCH_PRE_REGISTER_DATA,
  DELEET_PRE_REGISTER_DATA,
  GET_INDIVIDUAL_PRE_REGISTER,
} from "../../../redux/actionTypes";

export const getPreRegistationMiddleWare = createAsyncThunk(
  GET_PRE_REGISTRATION,
  async ({ id }, { getState, rejectWithValue }) => {
    const { preRegistrationReducers } = getState();

    try {
      const { data } = await getRequest(
        `${APIROUTES.PREREGISTRATION.GET_PRE_REGISTRATION}${id}/${preRegistrationReducers?.pageNo}`
      );
      console.log(data);
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const postPreRegistrationMiddleWare = createAsyncThunk(
  POST_CREATE_NEW_PROJECT,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${APIROUTES.PREREGISTRATION.POST_CREATE_NEW_PROJECT}${payload?.eventId}/${payload?.eventYear}/${payload?.eventName}`,
        payload
      );
      return data;
    } catch (error) {
      if (error.response?.data?.message) {
        window.alert("Registration No  Already Exist");
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error);
    }
  }
);
export const postPreRegistrationUploadFile = createAsyncThunk(
  POST_UPLOAD_FILE,
  async ({ formData, id, eventDetails }, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${APIROUTES.PREREGISTRATION.POST_UPLOAD_FILE}eventYear=${eventDetails?.year}&eventName=${eventDetails?.event}`,
        formData
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);

export const patchGetPreMiddleWare = createAsyncThunk(
  PATCH_PRE_REGISTER_DATA,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.PREREGISTRATION.PATCH_PRE_REGISTER_DATA}${payload?.id}`,
        payload
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);

export const deletePreMiddleWare = createAsyncThunk(
  DELEET_PRE_REGISTER_DATA,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.PREREGISTRATION.DELEET_PRE_REGISTER_DATA}`,
        payload
      );

      return data;
    } catch (error) {
      if (error.response?.data.error?.message) {
      }
      return rejectWithValue(error.response?.data.error?.message);
    }
  }
);
export const getPreGetIndividualMiddleWare = createAsyncThunk(
  GET_INDIVIDUAL_PRE_REGISTER,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${APIROUTES.PREREGISTRATION.GET_INDIVIDUAL_PRE_REGISTER}${id}`
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
