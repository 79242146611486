import React from "react";
import { Image } from "primereact/image";
import Logo from "../../assets/logoimg.png";
import "./Dashbored.scss";
import Profile from "../../components/Profile";
function Dashbored() {
  return (
    <div>
      <Profile />
      <div className="logo_container">
        <Image src={Logo} alt="Image" width="260" height="460" />
      </div>
    </div>
  );
}

export default Dashbored;
