import { createSlice } from "@reduxjs/toolkit";
import { getRoleMasterMiddleWare ,postrollmasterMiddleWare} from "./rolemasterMiddleWare";

const initialState = {
    loading: false,
    error: "",
    rollmaster: [],
    rollmasterdata: {}
};

const rollMasterReducers = createSlice({
    name: "rollmaster",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
        builder.addCase(getRoleMasterMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRoleMasterMiddleWare.fulfilled, (state, action) => {
            console.log(action,"sdf")
            state.loading = false;
            state.rollmaster = action.payload;
        });
        builder.addCase(getRoleMasterMiddleWare.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(postrollmasterMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postrollmasterMiddleWare.fulfilled, (state, action) => {
            console.log(action, "sdf")
            state.loading = false;
            state.rollmasterdata = action?.payload;
        });
        builder.addCase(postrollmasterMiddleWare.rejected, (state, action) => {
            console.log(action, "sdf")
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });


    },
});

export default rollMasterReducers.reducer;