import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { useDispatch } from "react-redux";
import { deleteverifiedMiddleWare } from "../components/auth/store_delete/deleteVerifiedMiddleWare";
import { getVerifieddataMiddleWare } from "../module/Verified_business_idea/store/verifieddataMiddleware";
function Delete_verified({ dataa, id }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {

    let multipledata=dataa.map((DATA)=>DATA._id)


    dispatch(
      deleteverifiedMiddleWare({
        projectIdList: multipledata,
      })
    )

      .then((res) => {
        if (res) {
          setVisible(false);
          dispatch(getVerifieddataMiddleWare({ id }));
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-trash"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 55,
          height: 55,
          margin: 10,
        }}
      ></Button>
      <Dialog
        visible={visible}
        className="DeleteEvent_modelcontainer"
        closable={false}
      >
        <div className="innerdelete_container">
          <h2>Confirm Delete?</h2>
          <div className="Deletebuttons">
            <Button
              label="Cancel"
              className="eachButtons"
              onClick={() => setVisible(false)}
            />
            <Button
              label="Delete"
              className="eachButtons"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Delete_verified;
