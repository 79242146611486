import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest ,postRequest} from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_ROLLMASTER ,POST_ROLLMASTER} from "../../../redux/actionTypes";

export const getRoleMasterMiddleWare = createAsyncThunk(
    GET_ROLLMASTER,
    async (_a, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(APIROUTES.ROLEMASTER.GET_ROLEMASTER);
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);

export const postrollmasterMiddleWare = createAsyncThunk(
    POST_ROLLMASTER,
    async (payload, { rejectWithValue }) => {
        console.log(payload,"payload data")
        try {
            // const { data } = await postRequest(APIROUTES.ROLEMASTER.POST_ROLEMASTER,payload);

            // return data;
            
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);