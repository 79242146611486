import React, { useEffect } from "react";
import "./EventAward.scss";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import Profile from "../../components/Profile";
import { useNavigate, useParams } from "react-router-dom";
import Addyear from "../../components/Addyear";
import { useDispatch, useSelector } from "react-redux";
import { getEventAwardDataMiddleWare } from "./store/eventAwardMIddleWare";

function EventAward() {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (val) => {
    navigate(`/awards/registration/${val._id}/${name}`, {
      state: {
        event: val.event,
        year: val.year,
      },
    });
  };
  const handleGetEvent = () => {
    dispatch(getEventAwardDataMiddleWare({ name }));
  };
  const { data, loading } = useSelector(({ eventReducers }) => {
    return {
      loading: eventReducers.isLoading,
      data: eventReducers.allAwards,
    };
  });

  useEffect(() => {
    handleGetEvent();
  }, []);

  const home = {
    label: "Event And Program",
    url: "/awards",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/awards/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];
  return (
    <>
      <div>
        <div className="top-row">
          <BreadCrumb home={home} model={items} />
          <Profile />
        </div>
        <div className="overall_container">
          <Addyear name={name} />
          <div
            className="Awards_carditem_container"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridGap: "16px",
            }}
          >
            {data.map((val) => (
              <div className="carditem_container">
                <Card
                  className="carditems"
                  onClick={() => {
                    handleNavigate(val);
                  }}
                >
                  {val.event}
                  <h1>{val.year}</h1>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventAward;
