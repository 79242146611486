import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./index.scss";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import {
  getAwardsMiddleWare,
  postNewAward,
} from "../module/Awards/store/awardsMiddleWare";

function Addonname() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const handleSubmit = () => {
    dispatch(postNewAward({ eventName: name }))
      .then((res) => {
        setVisible(false);
        dispatch(getAwardsMiddleWare({}));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-plus"
        style={{
          borderRadius: 10,
          backgroundColor: "#0B1B6E",
          width: 268,
          height: 60,
          margin: 10,
          marginLeft: 20,
        }}
        label="Add Program and Event"
      ></Button>
      <Dialog
        visible={visible}
        className="Event_modelcontainer"
        closable={false}
      >
        <div className="innerevent_container">
          <h3>Event/Program Name</h3>
          <InputText
            className="eventinput"
            onChange={(e) => setName(e.target.value)}
          />

          <div className="button_container">
            <Button
              label="Cancel"
              className="butt_txt"
              onClick={() => setVisible(false)}
            />
            <Button
              label="Add Event"
              className="butt_txt"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Addonname;
