import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { patchRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import { PATCH_VERIFIEDDELETE } from "../../../redux/actionTypes";



export const deleteverifiedMiddleWare=createAsyncThunk(
    PATCH_VERIFIEDDELETE,
    async (payload,{rejectWithValue})=>{
        try{
            const {data}=await patchRequest(APIROUTES.DELETE.PATCH_DELETE, payload)
            // console.log(data, "delete");
            return data
        } catch(error){
            if(error.response?.data.error?.message){
            }
          return rejectWithValue(error.response?.data.error?.message)
        }
    }

)