import { createSlice } from "@reduxjs/toolkit";
import { getrollaccesSearchMiddleWare } from "./rollaccessMiddleWare";

const initialState = {
    loading: false,
    error: "",
    rollaccessearchdata: [],
};

const RollaccessearchReducers = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
        builder.addCase(getrollaccesSearchMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getrollaccesSearchMiddleWare.fulfilled, (state, action) => {
            console.log(action,"sdf")
            state.loading = false;
            state.rollaccessearchdata = action.payload;
        });
        builder.addCase(getrollaccesSearchMiddleWare.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

    },
});

export default RollaccessearchReducers.reducer;