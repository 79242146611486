import React, { useEffect, useState } from "react";
import "./Profile.scss";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileMiddleWare } from "../components/auth/authMiddleWare";

const Profile = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const logoutHandler = () => {
    Cookies.remove("token");
    navigate("/");
  };

  const handlegetprofile = () => {
    dispatch(getProfileMiddleWare({}));
  };
  const { data, loading } = useSelector(({ profileReducers }) => {
    return {
      loading: profileReducers?.loading,
      data: profileReducers?.profile,
    };
  });

  useEffect(() => {
    handlegetprofile();
  }, []);

  

  return (
    <div className="container">
      <h3 style={{ color: "#000000", fontSize: 18 }}> {data.name}</h3>
      <Button
        icon="pi pi-user"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 45,
          height: 45,
          margin: 10,
        }}
        onClick={() => setVisible(true)}
      ></Button>

      <Dialog
        visible={visible}
        className="modelcontainer"
        onHide={() => setVisible(false)}
      >
        <div className="profile_contents">
          <h3>Name</h3>
          <InputText
            value={data?.name}
            className="inputs_container"
          ></InputText>

          <h3>Email ID</h3>
          <InputText
            value={data?.email}
            placeholder="email@gmail.com"
            className="inputs_container"
          />

          <h3>Position</h3>
          <InputText
            value={data?.role?.role}
            placeholder="Volunteer"
            className="inputs_container"
          />

          <h3>Phone</h3>
          <InputText
            value={data?.contactNo}
            placeholder="+91 9876543455"
            className="inputs_container"
          />
          <Button
            label="Logout"
            className="Logouttxt"
            onClick={logoutHandler}
          ></Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Profile;
