import React, { useEffect, useState } from 'react'
import { InputText } from "primereact/inputtext";
import { getSearchMiddleWare } from '../PreRegistration/store/getPreSearchMiddleWare';

import { useDispatch, useSelector } from 'react-redux';
import { getPreRegistationMiddleWare } from './store/preRegistrationMiddleware.';

function PreRegisterSearch({ id }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('')
  const handlegetsearch = () => {
    dispatch(getSearchMiddleWare({ id, search }))

  }
  const { data, loading } = useSelector(
    ({
      getSearchPre }) => {
      return {
        loading: getSearchPre?.loading,
        data: getSearchPre?.searchdata,
      };
    },
  );
  useEffect(() => {
    if (search) {
      handlegetsearch()
    }
    else {
      dispatch(getPreRegistationMiddleWare({ id }))
    }
  }, [search])
  return (
    <div >
      <span className="p-input-icon-right">
        <InputText placeholder="Search" className="inputtxt" onChange={(e) => setSearch(e.target.value)} />
        <i className="pi pi-search" style={{ marginRight: 8, color: 'black', marginTop: -4 }} />

      </span>
    </div>
  )
}

export default PreRegisterSearch