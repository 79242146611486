export const LOGIN = "login/LOGIN";
export const LOGOUT = "login/LOGOUT";
export const GET_ALL_ACCESS_CONTROL_PARAMETER = 'get/GET_ALL_ACCESS_CONTROL_PARAMETER';
export const GET_ACCESS_CONTROL_PARAMETER = 'get/GET_ACCESS_CONTROL_PARAMETER';
export const PATCH_ACCESS_CONTROL_PARAMETER = 'patch/PATCH_ACCESS_CONTROL_PARAMETER';
export const PATCH_CHANGE_STATUS_ACCESS_CONTROL_PARAMETER = 'patch/PATCH_CHANGE_STATUS_ACCESS_CONTROL_PARAMETER';
export const POST_ACCESS_CONTROL_PARAMETER_ID = 'post/POST_ACCESS_CONTROL_PARAMETER_ID';
export const DELETE_ACCESS_CONTROL_PARAMETER_BY_ID = 'delete/DELETE_ACCESS_CONTROL_PARAMETER_BY_ID';
export const POST_AWARDS='award/create-new-event';
export const GET_ALL_AWARDS='award/get-all-event';
export const GET_ALL_EVENTAWARDS='year/full-event/list/?event=DR KALAM AWARDS'
export const POST_EVENTAWARD="year/create-event"
export const GET_PROFILE='get/admin-profile'
export const GET_ROLLACCESS='users/admin-all-user'
export const PATCH_ROLLACCESS='users/update-user'
export const POST_ROLLACCESS='users/add-user'
export const GET_PRE_REGISTRATION='pre-registration/get-all'
export const POST_CREATE_NEW_PROJECT='pre-registration/add-new-project/64f6da079603bb7ba9129b1e/2023/DR KALAM AWARDS'
export const GET_POST_REGISTER='post-registration/get-all/64f97d8a149e96e62347ba4b/1'
export const GET_POST_SELECTED_REGISTER='post-registration/get-all-project/64f6da079603bb7ba9129b1e/selected/1'
export const GET_POST_WAITING_REGISTER='post-registration/get-all-project/64f6da079603bb7ba9129b1e/waiting/1'
export const GET_POST_REJECTED_REGISTER='post-registration/get-all-project/64f6da079603bb7ba9129b1e/rejected/1'
export const GET_POST_BUSSINESSIDEA_REGISTER='post-registration/get-all-project/64f6da079603bb7ba9129b1e/businessidea/1'
export const GET_PARTICIEPENTDEATILS='post-registration/team-details-view/64f97dad149e96e62347ba5b' 
export const POST_UPLOAD_FILE='pre-registration/upload-xldata?eventYear=2024&eventName=DR KALAM AWARDS'
export const GET_ROLLMASTER='role-master/admin-role'
export const GET_VERIFIEDTABLEDATA='verified-business-idea/get-all/64f97d8a149e96e62347ba4b/1'
export const GET_SEARCHDATA='verified-business-idea/search-project/64f97d8a149e96e62347ba4b/w'
export const GET_DOWNLOAD='verified-business-idea/excel/64f97d8a149e96e62347ba4b'
export const POST_ROLLMASTER='role-master/create-role'
export const PATCH_PRE_REGISTER_DATA='pre-registration/update-indidual-project/64ff406228d8780223724c74'
export const DELEET_PRE_REGISTER_DATA='pre-registration/delete-project'
export const GET_INDIVIDUAL_PRE_REGISTER='pre-registration/indidual-project/64ff406228d8780223724c74'
export const GET_SEARCH_PRE_REGISTER='pre-registration/search-project'
export const  GET_PRE_DOWNLOAD_REGISTER='pre-registration/download-excel'
export const GET_POST_SEARCH_ALL_RECORDS='post-registration/search-project/'
export const GET_POST_SELECTED_SEARCH='post-registration/search-project/64f97d8a149e96e62347ba4b/selected'
export const GET_POST_WAITING_SEARCH='post-registration/search-project/64f97d8a149e96e62347ba4b/waiting'
export const  GET_POST_REJECTED_SEARCH='post-registration/search-project/64f97d8a149e96e62347ba4b/rejected'
export const   GET_POST_BUSSINESSIDEA_SEARCH='post-registration/search-project/64f97d8a149e96e62347ba4b/businessidea'
// export const GET_POST_PARTICIPENTDETAILS='post-registration/team-details-view'
export const PATCH_UPDATE_POST_REGISTER='post-registration/update-project-status/'
export const GET_ROLLACCESSEARCH='users/search-user/'
export const PATCH_VERIFIEDDELETE='verified-business-idea/remove-verifiedbusinessidea'
export const PATCH_ACTIVATESTATUS='users/deactivate-user/'
export const POST_PARTICIPENT_DETAILS='post-registration/team-details-view/'
