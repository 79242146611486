import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthLayout from '../components/Layout/AuthLayout'
import Login from '../module/Login'
import UnProtectedRoutes from './UnProtectedRoutes'
import Dashbored from '../module/Dashbored/Dashbored'
import Awards from '../module/Awards/Awards'
const AuthRoute = () => {
    return (
        <>
            <Routes>
                <Route element={<UnProtectedRoutes />}>
                    <Route path="/auth" element={<AuthLayout />} exact>
                        <Route path="login" element={<Login />} exact />
                        
                    </Route>
                </Route>
            </Routes>
        </>
    )
}

export default AuthRoute