import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {
  GET_ALL_EVENTAWARDS,
  POST_EVENTAWARD,
} from "../../../redux/actionTypes";

export const getEventAwardDataMiddleWare = createAsyncThunk(
  GET_ALL_EVENTAWARDS,
  async ({ name }, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${APIROUTES.EVENT.GET_ALL_EVENTAWARDS}${name}`
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        alert(error.message);
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const postNewEventAwardMiddleware = createAsyncThunk(
  POST_EVENTAWARD,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        APIROUTES.EVENT.CREATE_NEW_EVENTAWARD,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);
