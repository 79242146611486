import { createSlice } from "@reduxjs/toolkit";
import { getSearchMiddleWare } from "../store/getPreSearchMiddleWare";

const initialState = {
    loading: false,
    error: "",
    searchdata: [],
};

const SearchPreReducer = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getSearchMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSearchMiddleWare.fulfilled, (state, action) => {
          
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getSearchMiddleWare.rejected, (state, action) => {


            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

    },
});

export default SearchPreReducer.reducer;