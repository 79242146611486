import { createSlice } from "@reduxjs/toolkit";
import { getSearchPostMiddleWare ,getPostSelectSearch,getPostWaitingSearch,getPostRejectedSearch,getPostBussinessIdeaSearch} from "../store/getSearchPostMiddleWare";

const initialState = {
    loading: false,
    error: "",
    searchdata: [],
};

const SearchPreReducer = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getSearchPostMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSearchPostMiddleWare.fulfilled, (state, action) => {
            console.log(action,"searchDataa")
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getSearchPostMiddleWare.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostSelectSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostSelectSearch.fulfilled, (state, action) => {
            console.log(action,"selectedSearch")
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getPostSelectSearch.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostWaitingSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostWaitingSearch.fulfilled, (state, action) => {
            console.log(action,"WaitingSearch")
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getPostWaitingSearch.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

        builder.addCase(getPostRejectedSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostRejectedSearch.fulfilled, (state, action) => {
            console.log(action,"WaitingSearch")
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getPostRejectedSearch.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostBussinessIdeaSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostBussinessIdeaSearch.fulfilled, (state, action) => {
            console.log(action,"WaitingSearch")
            state.loading = false;
            state.searchdata = action.payload;
        });
        builder.addCase(getPostBussinessIdeaSearch.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

    },
});
export default SearchPreReducer.reducer;