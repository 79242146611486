

import { createSlice } from "@reduxjs/toolkit";
import { getParticipentDetailsMiddleware } from "./participentDetailsMiddleware";

const initialState = {
    loading: false,
    error: "",
    preRegData: [],
    user: {}
};

const preRegistrationReducers = createSlice({
    name: "preRegistration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getParticipentDetailsMiddleware.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getParticipentDetailsMiddleware.fulfilled, (state, action) => {
            console.log(action, "sdf")
            state.loading = false;
            state.preRegData = action?.payload;
        });
        builder.addCase(getParticipentDetailsMiddleware.rejected, (state, action) => {
            console.log(action, "sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
    },
});

export default preRegistrationReducers.reducer;