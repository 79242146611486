import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreGetIndividualMiddleWare,
  getPreRegistationMiddleWare,
  patchGetPreMiddleWare,
} from "../PreRegistration/store/preRegistrationMiddleware.";

function PreEditData({ data, clear, id }) {
  const [visible, setVisible] = useState(false);
  const { dataa } = useSelector(({ preRegistrationReducers }) => {
    return {
      loading: preRegistrationReducers?.loading,
      dataa: preRegistrationReducers?.user,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPreGetIndividualMiddleWare({ id: data[0]?._id }))
      .then((res) => {
        console.log(res, "ad");
        setPreInputBox({
          registrationNo: res?.payload?.registrationNo,
          projectName: res?.payload?.projectName,
          projectSummary: res?.payload?.projectSummary,
          responsiblePersonName: res?.payload?.responsiblePersonName,
          responsiblePersonContactNo: res?.payload?.responsiblePersonContactNo,
          nameStudent1: res?.payload?.nameStudent1,
          nameStudent2: res?.payload?.nameStudent2,
          nameStudent3: res?.payload?.nameStudent3,
          nameStudent4: res?.payload?.nameStudent4,
          emailStudent1: res?.payload?.emailStudent1,
          emailStudent2: res?.payload?.emailStudent2,
          emailStudent3: res?.payload?.emailStudent3,
          emailStudent4: res?.payload?.emailStudent4,
          mobileStudent1: res?.payload?.mobileStudent1,
          mobileStudent2: res?.payload?.mobileStudent2,
          mobileStudent3: res?.payload?.mobileStudent3,
          mobileStudent4: res?.payload?.mobileStudent4,
          collegeStudent1: res?.payload?.collegeStudent1,
          collegeStudent2: res?.payload?.collegeStudent2,
          collegeStudent3: res?.payload?.collegeStudent3,
          collegeStudent4: res?.payload?.collegeStudent4,
          responsivePersonContactNo: res?.payload?.responsivePersonContactNo,
          responsivePersonName: res?.payload?.responsivePersonName,
        });
      })
      .catch(() => {});
  }, []);

  const [preInputBox, setPreInputBox] = useState({});
  const handleSubmitPre = () => {
    dispatch(
      patchGetPreMiddleWare({
        id: dataa?._id,
        registrationNo: dataa?.registrationNo,
        projectName: preInputBox.projectName,
        projectSummary: preInputBox.projectSummary,
        responsiblePersonName: preInputBox.responsiblePersonName,
        responsiblePersonContactNo: preInputBox.responsiblePersonContactNo,
        nameStudent1: preInputBox.nameStudent1,
        nameStudent2: preInputBox.nameStudent2,
        nameStudent3: preInputBox.nameStudent3,
        nameStudent4: preInputBox.nameStudent4,
        emailStudent1: preInputBox.emailStudent1,
        emailStudent2: preInputBox.emailStudent2,
        emailStudent3: preInputBox.emailStudent3,
        emailStudent4: preInputBox.emailStudent4,
        mobileStudent1: preInputBox.mobileStudent1,
        mobileStudent2: preInputBox.mobileStudent2,
        mobileStudent3: preInputBox.mobileStudent3,
        mobileStudent4: preInputBox.mobileStudent4,
        collegeStudent1: preInputBox.collegeStudent1,
        collegeStudent2: preInputBox.collegeStudent2,
        collegeStudent3: preInputBox.collegeStudent3,
        collegeStudent4: preInputBox.collegeStudent4,
        responsivePersonContactNo: preInputBox.responsivePersonContactNo,
        responsivePersonName: preInputBox.responsivePersonName,
      })
    )
      .then((res) => {
        if (res) {
          dispatch(getPreRegistationMiddleWare({ id }))
            .then(() => {
              clear([]);
            })
            .catch(() => {});
        }
      })
      .catch((error) => {
        console.log(error, "sdfgh");
      });
  };

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setPreInputBox({ ...preInputBox, [name]: value });
  };

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-pencil"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 55,
          height: 55,
          margin: 10,
        }}
      ></Button>

      <Dialog
        visible={visible}
        className="Add_modelcontainer"
        onHide={() => setVisible(false)}
        style={{ width: "1114px", height: "1172px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p className="ParticipentText">Participant Details </p>
            <div
              className="card justify-content-center align-items-center align-self-center"
              style={{ marginTop: 20 }}
            >
              <div className="Form">
                <label className="lable1">Registration No:</label>
                <div>
                  <InputText
                    value={preInputBox?.registrationNo}
                    name="registrationNo"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">Project Name :</label>
                <div>
                  <InputText
                    value={preInputBox.projectName}
                    name="projectName"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">name : </label>
                <div>
                  <InputText
                    value={preInputBox.nameStudent1}
                    name="nameStudent1"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">Email Id :</label>
                <div>
                  <InputText
                    value={preInputBox.emailStudent1}
                    name="emailStudent1"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">Conatct No:</label>
                <div>
                  <InputText
                    value={preInputBox?.mobileStudent1}
                    name="mobileStudent1"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">College :</label>
                <div>
                  <InputText
                    value={preInputBox?.collegeStudent1}
                    name="collegeStudent1"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">name : </label>
                <div>
                  <InputText
                    value={preInputBox.nameStudent2}
                    name="nameStudent2"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">Email Id :</label>
                <div>
                  <InputText
                    value={preInputBox.emailStudent2}
                    className="projectInputbox"
                    onChange={handleChangeText}
                    name="emailStudent2"
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">Conatct No:</label>
                <div>
                  <InputText
                    value={preInputBox.mobileStudent2}
                    className="textInput"
                    onChange={handleChangeText}
                    name="mobileStudent2"
                  />
                </div>

                <label className="lable2">College :</label>
                <div>
                  <InputText
                    value={preInputBox.collegeStudent2}
                    className="projectInputbox"
                    onChange={handleChangeText}
                    name="collegeStudent2"
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">name : </label>
                <div>
                  <InputText
                    value={preInputBox.nameStudent3}
                    name="nameStudent3"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">Email Id :</label>
                <div>
                  <InputText
                    value={preInputBox.emailStudent3}
                    name="emailStudent3"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">Conatct No:</label>
                <div>
                  <InputText
                    value={preInputBox.mobileStudent3}
                    name="mobileStudent3"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">College :</label>
                <div>
                  <InputText
                    value={preInputBox.collegeStudent3}
                    name="collegeStudent3"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">name : </label>
                <div>
                  <InputText
                    value={preInputBox.nameStudent4}
                    name="nameStudent4"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">Email Id :</label>
                <div>
                  <InputText
                    value={preInputBox.emailStudent4}
                    name="emailStudent4"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div className="Form">
                <label className="lable1">Conatct No:</label>
                <div>
                  <InputText
                    value={preInputBox.mobileStudent4}
                    name="mobileStudent4"
                    className="textInput"
                    onChange={handleChangeText}
                  />
                </div>

                <label className="lable2">College :</label>
                <div>
                  <InputText
                    value={preInputBox.collegeStudent4}
                    name="collegeStudent4"
                    className="projectInputbox"
                    onChange={handleChangeText}
                  />
                </div>
              </div>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <label className="projectSummaryText">Project Summary :</label>
                <div style={{ marginTop: 15 }}>
                  <InputTextarea
                    value={preInputBox.projectSummary}
                    autoResize
                    rows={5}
                    cols={30}
                    name="projectSummary"
                    style={{
                      width: 1020,
                      height: 150,
                      backgroundColor: "#f6f6f6",
                      borderStyle: "none",
                    }}
                    onChange={handleChangeText}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 1020,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  alignSelf: "center",
                }}
              >
                <label className="label3">Responsible person Name :</label>
                <InputText
                  className="inputField"
                  value={preInputBox.responsivePersonName}
                  onChange={handleChangeText}
                  name="responsivePersonName"
                />
                <label className="label3">Responsible person Contact :</label>
                <InputText
                  className="inputField"
                  value={preInputBox?.responsivePersonContactNo}
                  onChange={handleChangeText}
                  name="responsivePersonContactNo"
                />
              </div>
            </div>
            <div>
              <Button
                onClick={handleSubmitPre}
                className="submit"
                label="Save"
              ></Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PreEditData;
