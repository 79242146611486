import React from "react";
import Kalamimg from "../../assets/kalamImage.png";
import Logo from "../../assets/Finallogo.png";
import { Image } from "primereact/image";
import "./index.scss";

function Leftindex() {
  return (
    <div style={{ height: "100%" }}>
      <div className="logo">
        <Image src={Logo} alt="Image" width="80" height="125" />
      </div>
      <div className="quites_container">
        <Image src={Kalamimg} alt="Image" width="296" height="396" />
      </div>
      <div className="quites_container_txt">
        <h4 className="quites_text">
          “Dream is not that which you see while sleeping it is something that
          does not let you sleep.”
        </h4>
      </div>
    </div>
  );
}

export default Leftindex;
