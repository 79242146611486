import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../utility/commonServices";
import { APIROUTES } from "../../routes/apiRoutes";
import {  GET_PROFILE } from "../../redux/actionTypes";

export const getProfileMiddleWare = createAsyncThunk(
    GET_PROFILE,
    async (_a, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(APIROUTES.PROFILE.GET_PROFILE);
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);