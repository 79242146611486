

import { createSlice } from "@reduxjs/toolkit";
import { getPostRegistationMiddleWare, getRejectedData,getPostParticipentDetails, postPreRegistrationMiddleWare, patchUpdateMiddleWare, getSelectedData, getWaitingData, getBusinessIdeaData,getSearchPostMiddleWare, getPostSelectSearch, getPostWaitingSearch, getPostRejectedSearch, getPostBussinessIdeaSearch } from "./postRegisterMiddleware";

const initialState = {
    loading: false,
    error: "",
    postRegData: [],
    user: {}, pageNo: 1, count: 0
};

const postRegistrationReducers = createSlice({
    name: "postRegistration",
    initialState,
    reducers: {
        setSkip: (state, action) => {
            state.pageNo = action.payload;
        },


    },
    extraReducers: (builder) => {
        builder.addCase(getSearchPostMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSearchPostMiddleWare.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getSearchPostMiddleWare.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostRegistationMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostRegistationMiddleWare.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getPostRegistationMiddleWare.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

        builder.addCase(getBusinessIdeaData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBusinessIdeaData.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getBusinessIdeaData.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

        builder.addCase(getRejectedData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRejectedData.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getRejectedData.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

        builder.addCase(getSelectedData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSelectedData.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;


        });
        builder.addCase(getSelectedData.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getWaitingData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWaitingData.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action?.payload;
            state.count = action?.payload?.allProjectCount;
            

        });
        builder.addCase(getWaitingData.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });


        builder.addCase(patchUpdateMiddleWare.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action?.payload;
        });
        builder.addCase(patchUpdateMiddleWare.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        }); builder.addCase(patchUpdateMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostSelectSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostSelectSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getPostSelectSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostWaitingSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostWaitingSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getPostWaitingSearch.rejected, (state, action) => {

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

        builder.addCase(getPostRejectedSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostRejectedSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getPostRejectedSearch.rejected, (state, action) => {
            console.log(action, "sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostBussinessIdeaSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostBussinessIdeaSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.postRegData = action.payload;
            state.count = action?.payload?.allProjectCount;

        });
        builder.addCase(getPostBussinessIdeaSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(getPostParticipentDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPostParticipentDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.preRegData = action?.payload;
        });
        builder.addCase(getPostParticipentDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
    
    },
});
export const {
    setSkip,
} = postRegistrationReducers.actions;

export default postRegistrationReducers.reducer;