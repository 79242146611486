import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getrollaccesSearchMiddleWare } from "./auth/store_rollaccess/rollaccessMiddleWare";

function Rollaccessearch(id) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const handlegetsearch = () => {
    dispatch(getrollaccesSearchMiddleWare({ search }));
  };
  const { data, loading } = useSelector(({ RollaccessearchReducers }) => {
    return {
      loading: RollaccessearchReducers?.loading,
      data: RollaccessearchReducers?.rollaccessearchdata,
    };
  });
  useEffect(() => {
    if (search) {
      handlegetsearch();
    }
  }, [search]);

  return (
    <div>
      <span className="p-input-icon-right">
        <InputText
          placeholder="Search"
          className="inputtxt"
          onChange={(e) => setSearch(e.target.value)}
        />
        <i
          className="pi pi-search"
          style={{ marginRight: 8, color: "black", marginTop: -4 }}
        />
      </span>
    </div>
  );
}

export default Rollaccessearch;
