import { createSlice } from "@reduxjs/toolkit";
import { getVerifieddataMiddleWare } from "./verifieddataMiddleware";
import { getSearchdataMiddleWare } from "../../../components/auth/store_search/searchMiddleWare";

const initialState = {
    loading: false,
    error: "",
    verifiedData: [],
    count: 0,
    pageNo: 1
};
const VerifiedtabledataReducer = createSlice({
    name: "varifiedtabledata",
    initialState,
    reducers: {
        setPageNo: (state, action) => {
            state.pageNo = action.payload
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getSearchdataMiddleWare
            .pending, (state) => {
                state.loading = true;
            });
        builder.addCase(getSearchdataMiddleWare
            .fulfilled, (state, action) => {
                state.loading = false;
                state.verifiedData = action.payload;
            });
        builder.addCase(getSearchdataMiddleWare
            .rejected, (state, action) => {
                state.loading = false;
                state.token = "";
                state.error = typeof action.payload === "string" ? action.payload : "";
            });

        builder.addCase(getVerifieddataMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getVerifieddataMiddleWare.fulfilled, (state, action) => {
            state.loading = false;
            state.verifiedData = action.payload;
            state.count = action.payload?.allProjectCount
        });
        builder.addCase(getVerifieddataMiddleWare.rejected, (state, action) => {
            state.loading = false;
            state.token = "";
            state.error = typeof action.payload === "string" ? action.payload : "";
        });


    },
});
export const {
    setSkip,
} = VerifiedtabledataReducer.actions;

export default VerifiedtabledataReducer.reducer;
