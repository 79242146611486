import React, { useEffect, useState } from 'react'
import { InputText } from "primereact/inputtext";
import { getSearchPostMiddleWare, getPostSelectSearch, getPostWaitingSearch, getPostRejectedSearch, getPostBussinessIdeaSearch } from '../PostRegistration/store/postRegisterMiddleware';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessIdeaData, getPostRegistationMiddleWare, getRejectedData, getSelectedData, getWaitingData } from './store/postRegisterMiddleware';


function PostSearchInputBox({ tabData, id }) {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('')
    const handlegetsearch = () => {
        if (tabData == "postRegister") {
            dispatch(getSearchPostMiddleWare({ id, tabData, search }))
        }
        else if (tabData == "Selected") {
            dispatch(getPostSelectSearch({ id, tabData, search }))

        }
        else if (tabData == "Waiting") {
            dispatch(getPostWaitingSearch({ id, tabData, search }))
        }
        else if (tabData == "Rejected") {
            dispatch(getPostRejectedSearch({ id, tabData, search }))

        }
        else if (tabData == "BussinessIdea") {

            dispatch(getPostBussinessIdeaSearch({ id, tabData, search }))

        }
    }
    const handleget = () => {
        if (tabData == "postRegister") {
            dispatch(getPostRegistationMiddleWare({ id }))
        }
        else if (tabData == "Selected") {
            dispatch(getSelectedData({ id }))

        }
        else if (tabData == "Waiting") {
            dispatch(getWaitingData({ id }))
        }
        else if (tabData == "Rejected") {
            dispatch(getRejectedData({ id }))

        }
        else if (tabData == "BussinessIdea") {

            dispatch(getBusinessIdeaData({ id }))
        }
    }
    const { data, loading } = useSelector(
        ({
            getSearchPre }) => {
            return {
                loading: getSearchPre?.loading,
                data: getSearchPre?.searchdata,
            };
        },
    );
    useEffect(() => {
        if (search) {
            handlegetsearch()
        }
        else {
            handleget()
        }
    }, [search])
    return (
        <div >
            <span className="p-input-icon-right">
                <InputText placeholder="Search" className="inputtxt" onChange={(e) => setSearch(e.target.value)} />
                <i className="pi pi-search" style={{ marginRight: 8, color: 'black', marginTop: -4 }} />
            </span>
        </div>
    )
}

export default PostSearchInputBox