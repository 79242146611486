import { createSlice } from "@reduxjs/toolkit";
import { createAccessControlParameter, deleteAccessControlParameterById, fetchAccessControlParameterById, fetchAllAccessControlParameter, patchAccessControlParameterById, patchAccessControlParameterByStatus } from "./accessControlParameterMiddleware";

const initialState = {
    loading: false,
    error: "",
    data: [],
    pageNo: 1,
    size: 10
};

const accesscontrolparameterReducer = createSlice({
    name: "accesscontrolparameter",
    initialState,
    reducers: {
        setPageNo: (state, action) => {
            state.pageNo = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllAccessControlParameter.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllAccessControlParameter.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchAllAccessControlParameter.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(fetchAccessControlParameterById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAccessControlParameterById.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchAccessControlParameterById.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(patchAccessControlParameterById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(patchAccessControlParameterById.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(patchAccessControlParameterById.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(patchAccessControlParameterByStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(patchAccessControlParameterByStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(patchAccessControlParameterByStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(deleteAccessControlParameterById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteAccessControlParameterById.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(deleteAccessControlParameterById.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });
        builder.addCase(createAccessControlParameter.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createAccessControlParameter.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(createAccessControlParameter.rejected, (state, action) => {
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });



    }
})
export default accesscontrolparameterReducer.reducer;
