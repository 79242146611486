import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  patchUserMiddleWare,
  getUserMiddleWare,
} from "../module/RoleAccess/store/roleaccessMiddleware";
import { getRoleMasterMiddleWare } from "./auth/store/rolemasterMiddleWare";

function Edit({ data }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [initValues, setValue] = useState({
    name: data?.name,
    email: data?.email,
    contactNo: data?.contactNo,
    role: data?.role,
  });
  const [selectedCity, setSelectedCity] = useState(initValues?.role);
  const handleSubmit = () => {
    dispatch(
      patchUserMiddleWare({
        id: data?._id,
        name: initValues?.name,
        email: initValues?.email,
        contactNo: initValues?.contactNo,
        role: selectedCity?.name,
      })
    )
      .then((res) => {
        if (res) {
           setVisible(false);

          dispatch(getUserMiddleWare({}))
          
        }
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValue({ ...initValues, [name]: value });
  };

  const handleroleMaster = () => {
    dispatch(getRoleMasterMiddleWare({}));
  };

  const { datas, loading } = useSelector(({ getRoleMasterReducers }) => {
    return {
      loading: getRoleMasterReducers?.loading,

      datas: getRoleMasterReducers?.rollmaster,
    };
  });

  useEffect(() => {
    handleroleMaster();
  }, []);

  const cities = datas?.map((dat) => {
    return {
      name: dat?.role,
    };
  });

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-pencil"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 55,
          height: 55,
          margin: 10,
        }}
      ></Button>

      <Dialog
        visible={visible}
        className="Add_modelcontainer"
        onHide={() => setVisible(false)}
      >
        <div className="inner_container">
          <h4>Name</h4>

          <InputText
            placeholder="Enter Name"
            name="name"
            value={initValues?.name}
            onChange={handleChange}
            className="inputs_container"
          />

          <h4>Email ID</h4>

          <InputText
            placeholder="Enter Email"
            name="email"
            value={initValues?.email}
            onChange={handleChange}
            className="inputs_container"
          />

          <h4>Contact Number</h4>

          <InputText
            placeholder="Enter Contact Number"
            name="contactNo"
            value={initValues?.contactNo}
            onChange={handleChange}
            className="inputs_container"
          />

          <h4>Access</h4>

          <Dropdown
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            className="inputs_container"
          />

          <Button
            label="Submit"
            className="submit_txt"
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default Edit;
