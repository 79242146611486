import { createSlice } from "@reduxjs/toolkit";
import { deleteverifiedMiddleWare } from "./deleteVerifiedMiddleWare";

const initialState = {
    loading: false,
    error: "",
    deletedata: [],
};

const DeleteReducers = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deleteverifiedMiddleWare.fulfilled, (state, action) => {
            console.log(action, "sdf")
            state.loading = false;
            state.deletedata = action?.payload;
        });
        builder.addCase(deleteverifiedMiddleWare.rejected, (state, action) => {
            console.log(action, "sdf")
            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        }); builder.addCase(deleteverifiedMiddleWare.pending, (state) => {
            state.loading = true;
        });

    },
});

export default DeleteReducers.reducer;