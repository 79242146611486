import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import Cookies from "js-cookie";
import { TOKEN } from '../../utility/constant';
import {BASE_URL} from '../../utility/constant'

function PreDownload({ id }) {
  const token = Cookies.get(TOKEN);

  const handlebutton = async () => {

    let result = await fetch(`${BASE_URL}pre-registration/download-excel/${id}`,

      { method: 'GET', headers: { Authorization:  `Bearer ${token}` } }).then(r => r.blob())

    let alink = document.createElement("a");

    alink.href = window.URL.createObjectURL(result);

    alink.download = `download.xlsx`;

    alink.click();

  }
  return (
    <div>
      <Button
        icon="pi pi-download"
        onClick={handlebutton}
        style={{
          borderRadius: 80,
          backgroundColor: '#D3D3D3',
          width: 55,
          height: 55,
          marginRight:10,
          borderStyle: 'hidden',
          color: '#000000',
        }}
      ></Button>
    </div>
  );
}

export default PreDownload;

