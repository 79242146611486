import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import { POST_PARTICIPENT_DETAILS, GET_POST_REGISTER, GET_POST_SELECTED_REGISTER, GET_POST_WAITING_REGISTER, GET_POST_REJECTED_REGISTER, GET_POST_BUSSINESSIDEA_REGISTER, PATCH_ROLLACCESS, POST_CREATE_NEW_PROJECT, PATCH_UPDATE_POST_REGISTER, GET_POST_SELECTED_SEARCH, GET_POST_WAITING_SEARCH, GET_POST_REJECTED_SEARCH, GET_POST_BUSSINESSIDEA_SEARCH, GET_POST_SEARCH_ALL_RECORDS } from "../../../redux/actionTypes";

export const getPostRegistationMiddleWare = createAsyncThunk(
  GET_POST_REGISTER,
  async ({ id }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_REGISTER}${id}/${postRegistrationReducers.pageNo}`
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getSelectedData = createAsyncThunk(
  GET_POST_SELECTED_REGISTER,
  async ({ id }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();

    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_SELECTED_REGISTER}${id}/selected/${postRegistrationReducers.pageNo}`
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {

      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getWaitingData = createAsyncThunk(
  GET_POST_WAITING_REGISTER,
  async ({ id }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();

    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_WAITING_REGISTER}${id}/waiting/${postRegistrationReducers.pageNo}`
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        // Handle error appropriately
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);


export const getRejectedData = createAsyncThunk(
  GET_POST_REJECTED_REGISTER,
  async ({ id }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();

    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_REJECTED_REGISTER}${id}/rejected/${postRegistrationReducers.pageNo}`
      );
    
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        // Handle error appropriately
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);

// Async thunk action for "BusinessIdea" button
export const getBusinessIdeaData = createAsyncThunk(
  GET_POST_BUSSINESSIDEA_REGISTER,
  async ({ id }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();

    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_BUSSINESSIDEA_REGISTER}${id}/businessidea/${postRegistrationReducers.pageNo}`
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        // Handle error appropriately
      }
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const postPreRegistrationMiddleWare = createAsyncThunk(
  POST_CREATE_NEW_PROJECT,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${APIROUTES.PREREGISTRATION.POST_CREATE_NEW_PROJECT}`,
        payload
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const patchUpdateMiddleWare = createAsyncThunk(
  PATCH_UPDATE_POST_REGISTER,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.POSTREGISTER.PATCH_UPDATE_POST_REGISTER}`,
        payload
      );
      window.location.reload();
      return data;
    } catch (error) {
      if (error.response?.data.error?.message) {
      }
      return rejectWithValue(error.response?.data.error?.message);
    }
  }
);

export const getSearchPostMiddleWare = createAsyncThunk(
  GET_POST_SEARCH_ALL_RECORDS,

  async ({ id, tabData, search, }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_SEARCH_ALL_RECORDS}${id}/allproject/${search}?pageNo=${postRegistrationReducers.pageNo}`);
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getPostSelectSearch = createAsyncThunk(
  GET_POST_SELECTED_SEARCH,

  async ({ id, tabData, search }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_SELECTED_SEARCH}${id}/selected/${search}?pageNo=${postRegistrationReducers.pageNo}`
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getPostWaitingSearch = createAsyncThunk(
  GET_POST_WAITING_SEARCH,
  async ({ id, tabData, search }, { getState, rejectWithValue }) => {

    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_WAITING_SEARCH}${id}/waiting/${search}?pageNo=${postRegistrationReducers.pageNo}`
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getPostRejectedSearch = createAsyncThunk(
  GET_POST_REJECTED_SEARCH,

  async ({ id, tabData, search }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(
        `${APIROUTES.POSTREGISTER.GET_POST_REJECTED_SEARCH}${id}/rejected/${search}?pageNo=${postRegistrationReducers.pageNo}`
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getPostBussinessIdeaSearch = createAsyncThunk(
  GET_POST_BUSSINESSIDEA_SEARCH,

  async ({ id, tabData, search }, { getState, rejectWithValue }) => {
    const { postRegistrationReducers } = getState();
    try {
      const { data } = await getRequest(`${APIROUTES.POSTREGISTER.GET_POST_BUSSINESSIDEA_SEARCH}${id}/businessidea/${search}?pageNo=${postRegistrationReducers.pageNo}`);
     
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const getPostParticipentDetails = createAsyncThunk(
  POST_PARTICIPENT_DETAILS,
  async ({ id }, { getState, rejectWithValue }) => {
  
    try {
      const { data } = await getRequest(`${APIROUTES.POSTREGISTER.POST_PARTICIPENT_DETAILS}${id}`);
       return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
        //   Toast.danger({message: error.response.data.error.message});
      }
      // const typedError = error as Error;
      return rejectWithValue(error.response?.data?.error?.message);
    }
  },
);
