import React from "react";
import { Button } from "primereact/button";
import Cookies from "js-cookie";
import { TOKEN } from "../../utility/constant";
import {BASE_URL} from '../../utility/constant'

function PostDownload({ id, tabData }) {
  const token = Cookies.get(TOKEN);
  const handlebutton = async () => {
    if (tabData === "postRegister") {
      let result = await fetch(
        `${BASE_URL}post-registration/excel/${id}/allproject`,

        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      ).then((r) => r.blob());

      let alink = document.createElement("a");

      console.log(result, "result");

      alink.href = window.URL.createObjectURL(result);

      alink.download = `download.xlsx`;

      alink.click();
    } else if (tabData === "Selected") {
      let result = await fetch(
        `${BASE_URL}post-registration/excel/${id}/selected`,

        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      ).then((r) => r.blob());

      let alink = document.createElement("a");

      console.log(result, "result");

      alink.href = window.URL.createObjectURL(result);

      alink.download = `download.xlsx`;

      alink.click();
    } else if (tabData === "Waiting") {
      let result = await fetch(
        `${BASE_URL}post-registration/excel/${id}/waiting`,

        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      ).then((r) => r.blob());

      let alink = document.createElement("a");

      console.log(result, "result");

      alink.href = window.URL.createObjectURL(result);

      alink.download = `download.xlsx`;

      alink.click();
    } else if (tabData === "Rejected") {
      let result = await fetch(
        `${BASE_URL}post-registration/excel/${id}/rejected`,

        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      ).then((r) => r.blob());

      let alink = document.createElement("a");

      console.log(result, "result");

      alink.href = window.URL.createObjectURL(result);

      alink.download = `download.xlsx`;

      alink.click();
    } else if (tabData === "BussinessIdea") {
      let result = await fetch(
        `${BASE_URL}post-registration/excel/${id}/businessidea`,

        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      ).then((r) => r.blob());

      let alink = document.createElement("a");

      console.log(result, "result");

      alink.href = window.URL.createObjectURL(result);

      alink.download = `download.xlsx`;

      alink.click();
    }
  };
  return (
    <div>
      <Button
        icon="pi pi-download"
        onClick={handlebutton}
        style={{
          borderRadius: 80,
          backgroundColor: "#D3D3D3",
          width: 55,
          height: 55,
          marginRight:10,
          borderStyle: "hidden",
          color: "#000000",
        }}
      ></Button>
    </div>
  );
}

export default PostDownload;
