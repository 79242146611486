import React, { useEffect } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import "./Awards.scss";
import { Card } from "primereact/card";
import Profile from "../../components/Profile";
import Addonname from "../../components/Addonname";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAwardsMiddleWare } from "./store/awardsMiddleWare";

const Awards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGet = () => {
    dispatch(getAwardsMiddleWare({}));
  };

  const { data, loading } = useSelector(({ awardsReducers }) => {
    return {
      loading: awardsReducers.isLoading,
      data: awardsReducers.allAwards,
    };
  });

  useEffect(() => {
    handleGet();
  }, []);

  console.log(data, loading, "find new");

  const award = {
    label: "Event And Program",
    url: "./awards",
    className: "breadcrumb-home",
  };
  const handleNavigate = (id, name) => {
    navigate(`/awards/${id}/${name}`, {
      state: {
        id,
        name,
      },
    });
    // navigate({

    // })
  };

  return (
    <>
      <div>
        <div className="top-row">
          <BreadCrumb home={award} />
          <Profile />
        </div>
        <div className="overall_container">
          <Addonname />
          <div className="Awards_carditem_container">
            {data.map((cardData) => (
              // <Link to={`/awards/${cardData?._id}/${cardData?.eventName}`}>
              <Card
                className="carditem"
                onClick={() =>
                  handleNavigate(cardData?._id, cardData?.eventName)
                }
              >
                {cardData?.eventName}
              </Card>
              // </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
