import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./index.scss";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import {
  getEventAwardDataMiddleWare,
  postNewEventAwardMiddleware,
} from "../module/EventAward/store/eventAwardMIddleWare";

function Addyear({ name }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [year, setYear] = useState("");
  const handleSubmit = () => {
    dispatch(postNewEventAwardMiddleware({ eventName: name, eventYear: year }))
      .then((res) => {
        setVisible(false);
        dispatch(getEventAwardDataMiddleWare({ name }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-plus"
        style={{
          borderRadius: 5,
          backgroundColor: "#0B1B6E",
          width: 168,
          height: 60,
          margin: 10,
        }}
        label="Add Year"
      ></Button>
      <Dialog
        visible={visible}
        className="Event_modelcontainer"
        closable={false}
      >
        <div className="innerevent_container">
          <h3>Year of the Award</h3>
          <InputText
            className="eventinput"
            onChange={(txt) => setYear(txt.target.value)}
          />

          <div className="button_container">
            <Button
              label="Cancel"
              className="butt_txt"
              onClick={() => setVisible(false)}
            />
            <Button
              label="Add Year"
              className="butt_txt"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Addyear;
