import React, { useEffect } from "react";
import "./Verified.scss";
import { Card } from "primereact/card";
import Profile from "../../components/Profile";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEventAwardDataMiddleWare } from "../EventAward/store/eventAwardMIddleWare";

function Verified_year_details() {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (val) => {
    navigate(
      `/verifiedbusinessidea/verified_bussinessIdea_table/${val._id}/${name}`,
      {
        state: {
          event: val.event,
          year: val.year,
          _id: val._id,
        },
      }
    );
  };
  const handleGetEvent = () => {
    dispatch(getEventAwardDataMiddleWare({ name }));
  };

  const { data, loading } = useSelector(({ eventReducers }) => {
    return {
      loading: eventReducers.isLoading,
      data: eventReducers.allAwards,
    };
  });

  useEffect(() => {
    handleGetEvent();
  }, []);

  const home = {
    label: "Event And Program",
    url: "/verifiedbusinessidea",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/verifiedbusinessidea/verified_year_details/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];

  return (
    <>
      <div>
        <div className="top-row">
          <BreadCrumb home={home} model={items} />
          <Profile />
        </div>

        <div className="overall_container">
          <div className="container"></div>
          <div
            className="Awards_carditem_container"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridGap: "16px",
            }}
          >
            {data.map((val) => (
              <div className="carditem_container">
                <Card
                  className="carditems"
                  onClick={() => {
                    handleNavigate(val);
                  }}
                >
                  {val.event}
                  <h1>{val.year}</h1>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Verified_year_details;
