import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button"
import { Image } from 'primereact/image'
import * as XLSX from "xlsx";
import {
  getPreRegistationMiddleWare,
  postPreRegistrationUploadFile,
} from "./store/preRegistrationMiddleware.";

function PrePopupUpload({ id, eventDetails, setVisiblePopup }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [text, setText] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [dragging, setDragging] = useState(false);

  const toast = useRef(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

 
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];

    if (file) {
      if (file.name.endsWith(".xlsx") || file.name.endsWith(".csv")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setSelectedFile(file);
          } catch (error) {
            alert("File Upload Failed", error);
          }
        };

        reader.readAsBinaryString(file);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Only XLSX files are allowed",
        });
      }
    }
  };
  const handleSubmit = () => {
    console.log(selectedFile, "selectedFile");
    if (selectedFile) {
      const formData = new FormData();
      formData.append("xldata", selectedFile);
      dispatch(postPreRegistrationUploadFile({ formData, id, eventDetails }))
      .then((response) => {
        console.log(response.payload, "-----> response");
        if (response.payload.message == "Successfully File Uploaded") {
          dispatch(getPreRegistationMiddleWare({ id }));
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "File upload successfull.",
          });
          setTimeout(() => {
            setVisiblePopup(false);
          }, 2000);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "File Upload Failed.",
          });
          setTimeout(() => {
            setVisiblePopup(false);
          }, 2000);
        }
      })
        .catch((error) => {
          alert("File Upload Failed", error);
        });
    } else {
      alert("Please select a file before submitting.");
    }
  };

  const handleComponentClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      handleFileChange({ target: { files: [file] } });
    }
    setDragging(false);
  };

  return (
    <div>
      <div
        onClick={handleComponentClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        <div className="fileView">
          <div className="fileHead">
            <div
              className={`file-upload-box `}
            >
              <label htmlFor="file-input" className="upload-label">
                <i className="fas fa-cloud-upload-alt"></i>
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .csv"
                className="dotted-border"
                id="file-input"
                ref={fileInputRef}
                style={{ display: 'none' }}
              />

              {successMessage ? (
                <p style={{ textAlign: "center", marginTop: 100, color: "green" }}>
                  {successMessage}
                </p>
              ) : selectedFile ? (
                <p style={{ textAlign: "center", marginTop: 100 }}>
                  Selected file: {selectedFile.name}
                </p>
              ) : (
                <div >

                  <Image  src="https://i.ibb.co/GPnCYQr/Group-8234.png" style={{height:150}} ></Image>

                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      textAlign: "center",
                      color: "#909090",
                    }}
                  >

                    Click or Drag and drop file here to upload, or click to{" "}
                    <span style={{ color: "#575454", fontWeight: "bold" }}>
                      browse
                    </span>{" "}
                    your computer.
                  </p>
                </div>
              )}
            </div>

          </div>
        </div>

        <Toast ref={toast} />
      </div>
      <div className="submitBtn">
        <Button onClick={handleSubmit} style={{ backgroundColor: '#0B1B6E', height: 50, width: 150 }} label="Submit">

        </Button>
      </div>
    </div>
  );
}

export default PrePopupUpload;
