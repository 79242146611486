import { createSlice } from "@reduxjs/toolkit";
import {
  getUserMiddleWare,
  patchUserMiddleWare,
  postUserMiddleWare,
  patchdeactivateMiddleWare,
} from "./roleaccessMiddleware";
import { getrollaccesSearchMiddleWare } from "../../../components/auth/store_rollaccess/rollaccessMiddleWare";

const initialState = {
  loading: false,
  error: "",
  rollaccess_user: [],
  user: {},
  activestatus: {},
  count: 0,
  pageNo: 1,
};

const roleaccessReducers = createSlice({
  name: "roleAccess",
  initialState,
  reducers: {
    setSkip: (state, action) => {
      state.pageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getrollaccesSearchMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getrollaccesSearchMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.rollaccess_user = action.payload;
      state.count = action.payload.allUserCount;
    });
    builder.addCase(getrollaccesSearchMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });

    builder.addCase(getUserMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.rollaccess_user = action?.payload;
      state.count = action.payload.allUserCount;
    });
    builder.addCase(getUserMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(patchUserMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchUserMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action?.payload;
    });
    builder.addCase(patchUserMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(postUserMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postUserMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action?.payload;
    });
    builder.addCase(postUserMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(patchdeactivateMiddleWare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchdeactivateMiddleWare.fulfilled, (state, action) => {
      state.loading = false;
      state.activestatus = action?.payload;
    });
    builder.addCase(patchdeactivateMiddleWare.rejected, (state, action) => {
      state.loading = false;
      state.error = typeof action.payload === "string" ? action.payload : "";
    });
  },
});

export const { setSkip } = roleaccessReducers.actions;

export default roleaccessReducers.reducer;
