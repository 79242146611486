import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import "../ParticipentDetails/ParticipentDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { getParticipentDetailsMiddleware } from "./store/participentDetailsMiddleware";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const ParticipentDetails = ({}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id, _id } = useParams();
  console.log(id, _id, "id want indiv");

  const [validationErrors, setValidationErrors] = useState({});

  const validateField = (fieldName, value) => {
    if (!value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName} is required`,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    }
  };

  const handleInputChange = (fieldName, value) => {
    // Perform any field-specific validation if needed
    validateField(fieldName, value);
  };

  const handleSubmit = () => {
    navigate(-1);
  };
  const { data, loading } = useSelector(({ particepentDetailsReducers }) => {
    console.log(particepentDetailsReducers, "sdfghjk");
    return {
      loading: particepentDetailsReducers?.loading,
      data: particepentDetailsReducers?.preRegData,
    };
  });
  console.log("=====>datas participants", data);
  const handlegetPreRegister = () => {
    dispatch(getParticipentDetailsMiddleware({ id }));
  };
  useEffect(() => {
    handlegetPreRegister();
  }, []);

  return (
    <div>
      <p className="ParticipentText">Participant Details </p>
      <div
        className="card justify-content-center align-items-center align-self-center"
        style={{ marginTop: 20 }}
      >
        <div className="Form">
          <label className="lable1">Registration No:</label>
          <div>
            <InputText
              style={{
                width: 235,
                height: 50,
                backgroundColor: "#f6f6f6",
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "center",
                display: "grid",
                gridTemplateColumns: "auto",
                marginLeft: "auto",
                borderStyle: "none",
              }}
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.registrationNo}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label className="lable2">Project Name :</label>
          <div>
            <InputText
              style={{
                width: 509,
                height: 50,
                backgroundColor: "#f6f6f6",
                borderRadius: 5,
                borderStyle: "none",
              }}
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.projectName}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent1 === true ? "lable1" : "labledesable"
            }
          >
            Name :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent1 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent1}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent1 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent1 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent1}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent1 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent1 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent1}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent1 === true ? "lable1" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent1 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent1}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent2 === true ? "lable1" : "labledesable"
            }
          >
            Name1 :{" "}
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent2 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent2}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent2 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent2 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent2}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent2 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent2 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent2}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent2 === true ? "lable1" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent2 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent2}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            Name2 :{" "}
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent3 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent3}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent3 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent3}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent3 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent3 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent3}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent3 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent3}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent4 === true ? "lable1" : "labledesable"
            }
          >
            Name3 :{" "}
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent4 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent4}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent4 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent4 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent4}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent4 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent4 === true
                  ? {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                    }
                  : {
                      width: 235,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      marginLeft: "auto",
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent4}
            />
            {validationErrors["Registration No"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Registration No"]}
              </div>
            )}
          </div>

          <label
            className={
              data?.attatenceStudent4 === true ? "lable2" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              style={
                data?.attatenceStudent4 === true
                  ? {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                    }
                  : {
                      width: 509,
                      height: 50,
                      backgroundColor: "#f6f6f6",
                      borderRadius: 5,
                      borderStyle: "none",
                      color: "#CCCCCC",
                    }
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent4}
            />
            {validationErrors["Project Name"] && (
              <div style={{ color: "red" }}>
                {validationErrors["Project Name"]}
              </div>
            )}
          </div>
        </div>

        {/* Add more input fields as needed */}

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <label
            style={{
              marginRight: 5,
              color: "#1C2ECE",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            projectSummary :
          </label>
          <div style={{ marginTop: 15 }}>
            <InputTextarea
              autoResize
              rows={5}
              cols={30}
              style={{
                width: 1020,
                height: 150,
                backgroundColor: "#f6f6f6",
                borderStyle: "none",
              }}
              value={data?.projectSummary}
            />
          </div>
        </div>
        <div
          style={{
            width: 1020,
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "center",
          }}
        >
          <label className="label3">Responsible person Name :</label>
          <InputText
            className="inputField"
            onChange={(e) =>
              handleInputChange("Responsible person Name", e.target.value)
            }
            value={data?.responsivePersonName}
          />
          <label className="label3">Responsible person Contact :</label>
          <InputText
            className="inputField"
            onChange={(e) =>
              handleInputChange("Responsible person Contact", e.target.value)
            }
            value={data?.responsivePersonContactNo}
          />
        </div>
      </div>
      <div className="btnn">
        <Button onClick={handleSubmit} className="submit" label="Back"></Button>
      </div>
    </div>
  );
};

export default ParticipentDetails;
