import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {  GET_PRE_DOWNLOAD_REGISTER } from "../../../redux/actionTypes";

export const getPreDownloadMiddleWare = createAsyncThunk(
    GET_PRE_DOWNLOAD_REGISTER,
    async ({ id }, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(`${APIROUTES.PREREGISTRATION.GET_PRE_DOWNLOAD_REGISTER}${id.id}`);
            
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);