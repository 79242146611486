import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import {
  getPreRegistationMiddleWare,
  deletePreMiddleWare,
} from "../module/PreRegistration/store/preRegistrationMiddleware.";
import { useDispatch } from "react-redux";

function Delete({ dataa, setResponse, clear, id }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const _id = dataa.map((item, index) => {
    return item._id;
  });
  const handleSubmitPre = ({ dataa }) => {
    dispatch(
      deletePreMiddleWare({
        projectIdToDelete: dataa.map((d) => d._id),
      })
    )
      .then((res) => {
        console.log("data deleted", res.payload.message);
        setResponse(res.payload.message);
        if (res) {
          dispatch(getPreRegistationMiddleWare({ id }))
            .then(() => {
              clear([]);
              setVisible(false);
            })
            .catch(() => {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon="pi pi-trash"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 55,
          height: 55,
          margin: 10,
        }}
      ></Button>

      <Dialog
        visible={visible}
        className="DeleteEvent_modelcontainer"
        closable={false}
      >
        <div className="innerdelete_container">
          <h2>Confirm Delete?</h2>
          <div className="Deletebuttons">
            <Button
              label="Cancel"
              className="eachButtons"
              onClick={() => setVisible(false)}
            />
            <Button
              label="Delete"
              className="eachButtons"
              onClick={() => {
                handleSubmitPre({ dataa });
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Delete;
