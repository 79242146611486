import { createSlice } from "@reduxjs/toolkit";
import { getProfileMiddleWare } from "./authMiddleWare";

const initialState = {
    loading: false,
    error: "",
    profile: [],
};

const authReducers = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
        builder.addCase(getProfileMiddleWare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProfileMiddleWare.fulfilled, (state, action) => {
            console.log(action,"sdf")
            state.loading = false;
            state.profile = action.payload;
        });
        builder.addCase(getProfileMiddleWare.rejected, (state, action) => {
            console.log(action,"sdf")

            state.loading = false;
            state.error = typeof action.payload === "string" ? action.payload : "";
        });

    },
});

export default authReducers.reducer;