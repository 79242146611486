import React from "react";
import { Button } from "primereact/button";
import Cookies from "js-cookie";
import { TOKEN } from "../utility/constant";
import { BASE_URL } from "../utility/constant";

function Rollaccessdownload() {
  const token = Cookies.get(TOKEN);

  const handlebutton = async () => {
    let res = await fetch(`${BASE_URL}users/download-excel-user/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((r) => r.blob());
    let alink = document.createElement("a");
    alink.href = window.URL.createObjectURL(res);
    alink.download = `rollaccess.xlsx`;
    alink.click();
  };

  return (
    <div>
      <Button
        icon="pi pi-download"
        onClick={handlebutton}
        style={{
          borderRadius: 80,
          backgroundColor: "#D3D3D3",
          width: 55,
          height: 55,
          margin: 10,
          borderStyle: "hidden",
          color: "#000000",
        }}
      ></Button>
    </div>
  );
}

export default Rollaccessdownload;
