
import React, { useEffect, useState } from 'react'
import { InputText } from "primereact/inputtext";
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getSearchdataMiddleWare } from './auth/store_search/searchMiddleWare';
import { getVerifieddataMiddleWare } from '../module/Verified_business_idea/store/verifieddataMiddleware'


function Inputtexts({ id }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");


  const handlegetsearch = () => {
    dispatch(getSearchdataMiddleWare({ id, search }))

  }
  const { data, loading } = useSelector(
    ({
      getsearchDataReducer }) => {
      return {
        loading: getsearchDataReducer?.loading,
        data: getsearchDataReducer?.searchdata,
      };
    },
  );
  useEffect(() => {
    if (search) {
      handlegetsearch();
    } else {
      dispatch(getVerifieddataMiddleWare({ id }));
    }
  }, [search]);


  return (
    <div>
      <span className="p-input-icon-right">
        <InputText
          placeholder="Search"
          className="inputtxt"
          onChange={(e) => setSearch(e.target.value)}
        />
        <i
          className="pi pi-search"
          style={{ marginRight: 8, color: "black", marginTop: -4 }}
        />
      </span>
    </div>
  )

}
export default Inputtexts;
