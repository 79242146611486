import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../components/Addone.scss";
import PrePopup from "../module/PreRegistration/PrePopup";
import PrePopupUpload from "../module/PreRegistration/PrePopupUpload";

function Addone({ id, eventDetails, dataa }) {
  const [showButtons, setShowButtons] = useState(true);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Button
        onClick={() => {
          setShowButtons(!showButtons);
        }}
        icon="pi pi-plus"
        style={{
          borderRadius: 80,
          backgroundColor: "#0B1B6E",
          width: 55,
          height: 55,
          margin: 10,
        }}
      ></Button>
      <div className="card flex justify-content-center">
        {!showButtons && (
          <Button
            label="Add"
            className="fileBtn"
            onClick={() => setIsModalVisible(true)}
          />
        )}
        {isModalVisible && (
          <Dialog
            visible={isModalVisible}
            style={{ width: 1114, height: 1172 }}
            onHide={() => setIsModalVisible(false)}
          >
            <PrePopup
              id={id}
              eventDetails={eventDetails}
              visible={isModalVisible}
              dataa={dataa}
              onClose={closeModal}
            />
          </Dialog>
        )}
      </div>
      <div>
        {!showButtons && (
          <Button
            label="Import File"
            className="fileBtn"
            onClick={() => setVisiblePopup(true)}
          />
        )}
        <Dialog
          header={"Upload a File"}
          visible={visiblePopup}
          className="popupFile"
          onHide={() => setVisiblePopup(false)}
        >
          <PrePopupUpload
            id={id}
            eventDetails={eventDetails}
            setVisiblePopup={setVisiblePopup}
          />
        </Dialog>
      </div>
    </div>
  );
}

export default Addone;
