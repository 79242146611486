import React from "react";
import { Card } from "primereact/card";
import Profile from "../../components/Profile";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Registration() {
  const { id, name } = useParams();
  const navigate = useNavigate();

  const { state } = useLocation();
  // const { name } = state;
  const handlePreRegistration = () => {
    navigate(`/awards/preregistration/${id}/${name}`, {
      state: {
        event: state?.event,
        year: state?.year,
      },
    });
  };
  const handlePostRegistration = () => {
    navigate(`/awards/postregistration/${id}/${name}`, {
      state: {
        event: state?.event,
        year: state?.year,
      },
    });
  };
  const home = {
    label: "Event And Program",
    url: "/awards",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/awards/${id}/${name}`,
      className: "breadcrumb-home",
    },
    {
      label: "Registration",
      url: `/awards/registration/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];
  return (
    <>
      <div>
        <BreadCrumb home={home} model={items} />
        <Profile />
        <div className="overall_container">
          <div className="carditem_container">
            <Card className="carditem" onClick={handlePreRegistration}>
              Pre Registration
            </Card>
            <Card className="carditem" onClick={handlePostRegistration}>
              Post Registration
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
