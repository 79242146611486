const sideMenu = [
  {
    id: 0,
    label: "Dashboard",
    to: "/Dashbored",
  },
  {
    id: 1,
    label: "Awards",
    to: "/awards",
  },
  {
    id: 2,
    label: "Role Access",
    to: "/RoleAccess",
  },
  {
    id: 3,
    label: "Verified Business Idea",
    to: "/verifiedbusinessidea",
  },

  // {
  //   label: "User Password Reset Option",
  //   to: "/Password Reset",
  // },
  // {
  //   label: "Confirmation Of User Password Resetting",
  //   to: "/Password Resetting",
  // },
  // {
  //   label: "Current User Role Specification",
  //   to: "/Role Specification",
  // },
  // {
  //   label: "Acting Roles Allocation For A User",
  //   to: "/Roles Allocation",
  // },
  // {
  //   label: "System Menu Definition",
  //   to: "/Menu Definition",
  // },
  // {
  //   label: "Menu Items For A Mdi Menu Item",
  //   to: "/Menu Items",
  // },
  // {
  //   label: "Program Master Maintenance",
  //   to: "/Program Maintenance",
  // },
  // {
  //   label: "Entry Of SubMenu/Popup Menu Items",
  //   to: "/Popup Menuitems",
  // },

  // {
  //   label: "Entry Of Transfer Of Staff To Other Branches",
  //   to: "/ Transfer Of Staff",
  // },
  // {
  //   label: "User Role Wise Menu Allocation",
  //   to: "/Wise Menuallocation",
  // },
  // {
  //   label: "User Role Wise/ Id Wise Rights Allocation For Programs",
  //   to: "/Id Wise Rightsallocation",
  // },

  // {
  //   label: "Entry Of Staff Suspension / Relieving",
  //   to: "/Staff Suspension",
  // },
  // {
  //   label: "Assigning Of Priority To Popup Menus In A Sub/Popup Menu",
  //   to: "/Popup Menus",
  // },

  // {
  //   label: "Blocking /Deblocking Of Users From The Accessing The System",
  //   to: "/user accessing",
  // },
  // {
  //   label: "User Sign In/ Sign Out Option",
  //   to: "/sign option",
  // },
  // {
  //   label: "Tagging Photo Images To An Employee",
  //   to: "/Tagging employeeImages",
  // },
  // {
  //   label: "User Information Query",
  //   to: "/userquery",
  // },
  // {
  //   label: "Employee Details Query",
  //   to: "/employeequery",
  // },
  // {
  //   label: "Viewing Photo Images Of An Employee",
  //   to: "/View employeeImage",
  // },
  // {
  //   label: "Transfer History Details Enquiry For An Employee",
  //   to: "/Transfer History",
  // },
  // {
  //   label: "Password Change History Details For An Employee",
  //   to: "/Employe change password",
  // },
  // {
  //   label: "Unsuccesful Login Attempts Details For An Employee",
  //   to: "/Login Attempts",
  // },
  // {
  //   label: "Block/Deblock History Query For An Employee",
  //   to: "/Employee History Query",
  // },
  // {
  //   label: "Sign In/Sign Out Log Query For An Employee",
  //   to: "/Employee Log Query",
  // },
  // {
  //   label: "Default Role change history Maintenance",
  //   to: "/Default Role",
  // },
];

export default sideMenu;
