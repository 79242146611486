import React, { useState, useEffect } from "react";
import "../Verified_business_idea/VerifiedBussinessIdeaTable.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Inputtexts from "../../components/Inputtexts";
import { BreadCrumb } from "primereact/breadcrumb";
import Profile from "../../components/Profile";
import Download from "../../components/Download";
import { useDispatch, useSelector } from "react-redux";
import { getVerifieddataMiddleWare } from "./store/verifieddataMiddleware";
import Delete_verified from "../../components/Delete_verified";
import { setSkip } from "./store/verifiedReducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const VerifiedBussinessIdeaTable = () => {
  const [SelectedProducts, setSelectedProducts] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id, name } = useParams();

  const handlenav = (id) => {
    naviagte(`/participentDetails/${id}`);
  };

  const handlegetverifiedtabledata = () => {
    dispatch(getVerifieddataMiddleWare({ id }));
  };
  const { data, loading, count, skip } = useSelector(
    ({ getverifiedtableReducers }) => {
      return {
        loading: getverifiedtableReducers?.loading,
        data: getverifiedtableReducers?.verifiedData,
        count: getverifiedtableReducers?.count,
        skip: getverifiedtableReducers?.pageNo,
      };
    }
  );

  useEffect(() => {
    handlegetverifiedtabledata();
  }, [skip]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginate = {
    RowsPerPageDropdown: (options) => {
      return (
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Page:{" "}
        </span>
      );
    },
    CurrentPageReport: (options) => {
      const startIndex = parseInt(skip - 1) * 10 + 1;
      const endIndex =
        count > startIndex + 10 - 1 ? startIndex + 10 - 1 : count;

      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {startIndex} - {endIndex} of {count}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
            }}
            disabled={data?.allProjectData == "" ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={data?.allProjectData == "" ? true : false}
            onClick={() => {
              dispatch(setSkip(skip + 1));
            }}
          >
            <span className="p-3">Next</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(1));
            }}
            disabled={data?.allProjectData == "" ? true : false}
          >
            <span className="p-3">First</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={data?.allProjectData == "" ? true : false}
            onClick={() => {
              dispatch(setSkip(Math.ceil(count / 10)));
            }}
          >
            <span className="p-3">Last</span>
          </button>
        </>
      );
    },
  };

  const home = {
    label: "Event And Program",
    url: "/verifiedbusinessidea",
    className: "breadcrumb-home",
  };
  const items = [
    {
      label: "Years",
      url: `/verifiedbusinessidea/verified_year_details/${id}/${name}`,
      className: "breadcrumb-model",
    },
    {
      label: "Verified Business Idea",
      url: `/verifiedbusinessidea/verified_bussinessIdea_table/${id}/${name}`,
      className: "breadcrumb-model",
    },
  ];

  return (
    <div>
      <div className="top-row">
        <BreadCrumb home={home} model={items} />
        <Profile />
      </div>

      <div className="inputcontainer">
        <div style={{ width: "80%" }}>
          {SelectedProducts ? (
            <Delete_verified id={id} dataa={SelectedProducts} />
          ) : null}
        </div>

        <div className="search">
          <div className="download">
            {" "}
            <Download id={id} />
          </div>

          <Inputtexts id={id} />
        </div>
      </div>
      <div>
        <p className="preReg">Verified Business Idea</p>
      </div>
      <div className="table-container" tableStyle={{ minWidth: "50rem" }}>
        <DataTable
          value={data?.allProjectData}
          tableStyle={{ minWidth: "50rem" }}
          selection={SelectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode="checkbox"
          first={first}
          rows={10}
          paginator={true}
          paginatorTemplate={paginate}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={
            SelectedProducts == false
              ? (event) => handlenav(event.data._id)
              : null
          }
          onPage={onPageChange}
        >
          <Column field="select" header="" selectionMode="multiple"></Column>
          <Column field="registrationNo" header="Registration No"></Column>
          <Column field="nameStudent1" header="Team Lead Name"></Column>
          <Column field="projectName" header="Project Title"></Column>
          <Column field="emailStudent1" header="Email ID"></Column>
          <Column field="mobileStudent1" header="Contact"></Column>
          <Column field="teamSize" header="Team Size"></Column>
        </DataTable>
      </div>
    </div>
  );
};
export default VerifiedBussinessIdeaTable;
