import { fetchUrl } from '../utility/apiConfig';

export const loginApi = fetchUrl(`admin-login`)
export const APIROUTES = {
    APPOINTMENTS: "/appointments",
    ACCESSPARAMETER: {
        GET_ALL: 'access-parameters/get-all-access-parameters',
        CHANGE_STATUS: 'access-parameters/change-status-access-parameters',
        GET_BY_ID: 'access-parameters/get-access-parameters',
        PATCH_BY_ID: 'access-parameters/change-access-parameters',
        POST_FORM: 'access-parameters/create-access-parameters',
        DELETE_BY_ID: 'access-parameters/delete-single-access-parameters'
    }, LOGIN: {
        POST_LOGIN: 'admin-login'
    }, AWARDS: {
        GET_ALL_AWARD: 'award/get-all-event',
        CREATE_NEW_AWARD: 'award/create-new-event'
    },
    EVENT: {
        GET_ALL_EVENTAWARDS: 'year/full-event/list/?event=',
        CREATE_NEW_EVENTAWARD: 'year/create-event'
    }, PROFILE: {
        GET_PROFILE: 'admin-profile'
    }, ROLLACCESSUSER: {
        GET_USERDATA: 'users/admin-all-user',
        PATCH_USERDATA: 'users/update-user/',
        POST_USERDATA: 'users/admin-register',
        PATCH_ACTIVESTATUS:'users/deactivate-user/'
    },
    POSTREGISTER: {
        GET_POST_REGISTER: 'post-registration/get-all/',
        GET_POST_SELECTED_REGISTER: 'post-registration/get-all-project/',
        GET_POST_WAITING_REGISTER: 'post-registration/get-all-project/',
        GET_POST_REJECTED_REGISTER: 'post-registration/get-all-project/',
        GET_POST_BUSSINESSIDEA_REGISTER: 'post-registration/get-all-project/',
        GET_POST_SEARCH_ALL_RECORDS:'post-registration/search-project/',
        GET_POST_SELECTED_SEARCH:'post-registration/search-project/',
        GET_POST_WAITING_SEARCH:'post-registration/search-project/',
        GET_POST_REJECTED_SEARCH:'post-registration/search-project/',
        GET_POST_BUSSINESSIDEA_SEARCH:'post-registration/search-project/',
        PATCH_UPDATE_POST_REGISTER:'post-registration/update-project-status/',
        POST_PARTICIPENT_DETAILS:'post-registration/team-details-view/'

    },
    PREREGISTRATION: {
        POST_UPLOAD_FILE: 'pre-registration/upload-xldata?',
        GET_PRE_REGISTRATION: 'pre-registration/get-all/',
        POST_CREATE_NEW_PROJECT: 'pre-registration/add-new-project/',
        PATCH_PRE_REGISTER_DATA:'pre-registration/update-indidual-project/',
        DELEET_PRE_REGISTER_DATA:'pre-registration/delete-project',
        GET_INDIVIDUAL_PRE_REGISTER:'pre-registration/indidual-project/',
        GET_SEARCH_PRE_REGISTER:'pre-registration/search-project',
        GET_PRE_DOWNLOAD_REGISTER:'pre-registration/download-excel',
       
    },
    PARTICPENTDEATILS: {
        GET_PARTICIEPENTDEATILS: 'post-registration/team-details-view/',
        // GET_POST_PARTICIPENTDETAILS:'post-registration/team-details-view'
    },
    ROLEMASTER: {
        GET_ROLEMASTER: 'role-master/admin-role',
        POST_ROLEMASTER:'role-master/create-role'
    },
    VERIFIEDTABLEDATA: {
        GET_VERIFIEDTABLEDATA: 'verified-business-idea/get-all/',
        
    },
     SEARCHDATA:{
        GET_SEARCHDATA:'verified-business-idea/search-project/',
        GET_ROLLACCESSEARCH:'users/search-user/'
     },
     DOWNLOAD:{
        GET_DOWNLOAD:'verified-business-idea/excel/'
     },
     DELETE:{
        PATCH_DELETE:'verified-business-idea/remove-verifiedbusinessidea'
     }
    // APPOINTMENTS_CALENDAR: "/appointments-calendar",
    // APPOINTMENT_COUNT_DASHBOARD: "/dashboard/count/appointments",
    // APPOINTMENT_TYPE_GRAPH: "/dashboard/graph/appointment-statistics",
    // PATIENT_COUNT_DASHBOARD: "/dashboard/count/patients",
    // PATIENT_GENDER: "/dashboard/count/patient/gender-statistics",
    // BRANCHES: "/branches",
    // DASHBOARD_PATIENT_BAR_CHART: "/dashboard/graph/patients",
    // DASHBOARD_IN_OUT_PATIENT_BAR_CHART: "/dashboard/graph/patient-breakdown",
    // DESIGNATIONS: "/designations",
    // DEPARTMENTS: "/departments",
    // HOSPITALS: "/hospitals",
    // LABS: "/labs",
    // LABORATORY: "/lab-reports",
    // PATIENTS: "/patients",
    // STAFFS: "/users",
    // LAB_REPORT: "/lab-reports",
    // LOGIN: "/users/login",
    // CURRENT_USER: "/current-user",
    // VITALS: "/vitals",
    // SCRATCHPAD: "/scratchpad",
    // APPOINTMENT_SHEDULE: "/appointment-schedules",
    // APPOINTMENT_SLOTS: "/appointments/slots",
    // FILES: "/files",
    // ASSETS: "/assets",
    // ASSETS_CATEGORIES: "/asset-categories",
    // ASSETS_ITEMS: "/asset-items",
    // ASSETS_HISTORIES: "/asset-histories",
    // ASSETS_MAINTENANCES: "/asset-maintenances",
    // PHARMACY_DRUG: '/pharmacy-drugs',
    // PHARMACY_DRUG_STOCK: '/pharmacy/drugs/stocks'
};
