import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import "../ParticipentDetails/ParticipentDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreRegistationMiddleWare,
  postPreRegistrationMiddleWare,
} from "../PreRegistration/store/preRegistrationMiddleware.";
import "../PreRegistration/PrePopup.scss";

const PrePopup = ({ id, eventDetails, visible, onClose }) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateField = (fieldName, value) => {
    if (!value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName} is required`,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    }
  };

  const dispatch = useDispatch();
  const [registrationNo, setRegistrationNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectSummary, setProjectSummary] = useState("");
  const [responsiblePersonName, setResponsiblePersonName] = useState("");
  const [responsiblePersonContactNo, setResponsiblePersonContactNo] =
    useState("");
  const [nameStudent1, setNameStudent1] = useState("");
  const [nameStudent2, setNameStudent2] = useState("");
  const [nameStudent3, setNameStudent3] = useState("");
  const [nameStudent4, setNameStudent4] = useState("");
  const [emailStudent1, setEmailStudent1] = useState("");
  const [emailStudent2, setEmailStudent2] = useState("");
  const [emailStudent3, setEmailStudent3] = useState("");
  const [emailStudent4, setEmailStudent4] = useState("");
  const [mobileStudent1, setMobileStudent1] = useState("");
  const [mobileStudent2, setMobileStudent2] = useState("");
  const [mobileStudent3, setMobileStudent3] = useState("");
  const [mobileStudent4, setMobileStudent4] = useState("");
  const [collegeStudent1, setCollegeStudent1] = useState("");
  const [collegeStudent2, setCollegeStudent2] = useState("");
  const [collegeStudent3, setCollegeStudent3] = useState("");
  const [collegeStudent4, setCollegeStudent4] = useState("");

  const handleSubmit = () => {
    dispatch(
      postPreRegistrationMiddleWare({
        eventId: id,
        eventYear: eventDetails?.year,
        eventName: eventDetails?.event,
        registrationNo: registrationNo,
        projectName: projectName,
        projectSummary: projectSummary,
        responsiblePersonName: responsiblePersonName,
        responsiblePersonContactNo: responsiblePersonContactNo,
        nameStudent1: nameStudent1,
        nameStudent2: nameStudent2,
        nameStudent3: nameStudent3,
        nameStudent4: nameStudent4,
        emailStudent1: emailStudent1,
        emailStudent2: emailStudent2,
        emailStudent3: emailStudent3,
        emailStudent4: emailStudent4,
        mobileStudent1: mobileStudent1,
        mobileStudent2: mobileStudent2,
        mobileStudent3: mobileStudent3,
        mobileStudent4: mobileStudent4,
        collegeStudent1: collegeStudent1,
        collegeStudent2: collegeStudent2,
        collegeStudent3: collegeStudent3,
        collegeStudent4: collegeStudent4,
      })
    )
      .then((res) => {
        onClose();
        dispatch(getPreRegistationMiddleWare({ id }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="conatinerr">
      <div>
        <p className="ParticipentText">Participant Details </p>
        <div
          className="card justify-content-center align-items-center align-self-center"
          style={{ marginTop: 20 }}
        >
          <div className="Form">
            <label className="lable1">Registration No:</label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setRegistrationNo(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">Project Name :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setProjectName(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">name : </label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setNameStudent1(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">Email Id :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setEmailStudent1(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">Conatct No:</label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setMobileStudent1(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">College :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setCollegeStudent1(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">name : </label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setNameStudent2(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">Email Id :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setEmailStudent2(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">Conatct No:</label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setMobileStudent2(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">College :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setCollegeStudent2(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">name : </label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setNameStudent3(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">Email Id :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setEmailStudent3(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">Conatct No:</label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setMobileStudent3(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">College :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setCollegeStudent3(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">name : </label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setNameStudent4(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">Email Id :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setEmailStudent4(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>
          <div className="Form">
            <label className="lable1">Conatct No:</label>
            <div>
              <InputText
                className="textInput"
                onChange={(e) => setMobileStudent4(e.target.value)}
              />
              {validationErrors["Registration No"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Registration No"]}
                </div>
              )}
            </div>

            <label className="lable2">College :</label>
            <div>
              <InputText
                className="projectInputbox"
                onChange={(e) => setCollegeStudent4(e.target.value)}
              />
              {validationErrors["Project Name"] && (
                <div style={{ color: "red" }}>
                  {validationErrors["Project Name"]}
                </div>
              )}
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <label className="projectSummaryText">Project Status :</label>
            <div style={{ marginTop: 15 }}>
              <InputTextarea
                autoResize
                rows={5}
                cols={30}
                style={{
                  width: 1020,
                  height: 150,
                  backgroundColor: "#f6f6f6",
                  borderStyle: "none",
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: 1020,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "center",
            }}
          >
            <label className="label3">Responsible person Name :</label>
            <InputText
              className="inputField"
              onChange={(e) => setResponsiblePersonName(e.target.value)}
            />
            <label className="label3">Responsible person Contact :</label>
            <InputText
              className="inputField"
              onChange={(e) => setResponsiblePersonContactNo(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            className="submit"
            label="Save"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default PrePopup;
