import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import {
  GET_ROLLACCESS,
  PATCH_ROLLACCESS,
  POST_ROLLACCESS,
  PATCH_ACTIVATESTATUS,
} from "../../../redux/actionTypes";

export const getUserMiddleWare = createAsyncThunk(
  GET_ROLLACCESS,
  async (_a, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(APIROUTES.ROLLACCESSUSER.GET_USERDATA);
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
      }

      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const patchUserMiddleWare = createAsyncThunk(
  PATCH_ROLLACCESS,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        `${APIROUTES.ROLLACCESSUSER.PATCH_USERDATA}${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
      }

      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);

export const postUserMiddleWare = createAsyncThunk(
  POST_ROLLACCESS,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        APIROUTES.ROLLACCESSUSER.POST_USERDATA,
        payload
      );

      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
      }

      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
export const patchdeactivateMiddleWare = createAsyncThunk(
  PATCH_ACTIVATESTATUS,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await patchRequest(
        APIROUTES.ROLLACCESSUSER.PATCH_ACTIVESTATUS,
        payload
      );
      return data;
    } catch (error) {
      if (error.response?.data?.error?.message) {
      }

      return rejectWithValue(error.response?.data?.error?.message);
    }
  }
);
