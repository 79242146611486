import logo from "./logo.svg";
import "./App.css";
import { Button } from "primereact/button";
import Header from "./uikit/MainComponent/Header";
import MainComponent from "./uikit/MainComponent";
import AuthRoute from "./routes/AuthRoute";
import { Route, Routes } from "react-router-dom";
import Unauthorized from "./components/common/unAthorized/unAuthorized";
import ProtectedLayout from "./uikit/MainComponent/ProtectedLayout";
//import Dashbored from './uikit/MainComponent/Sidebar/Dashbored';
import AccessControlParameter from "./module/TableView/AccessControlParameter";
import Awards from "./module/Awards/Awards";
import RoleAccess from "./module/RoleAccess/RoleAccess";
import Dashbored from "./module/Dashbored/Dashbored";
import Verified_business_idea from "./module/Verified_business_idea/Verified_business_idea";
import Loginpage from "./module/Login/index";

// import Registration from './module/RegistrationModule/Registration';
import PreRegistration from "./module/PreRegistration/PreRegistration";
import Registration from "./module/Awards/Registration";
import PostRegistration from "./module/PostRegistration/PostResgistraton";
import ParticipentDetails from "./module/ParticipentDetails/ParticipentDetails";

import VerifiedBussinessIdeaTable from "./module/Verified_business_idea/VerifiedBussinessIdeaTable";
import EventAward from "./module/EventAward/EventAwards";
import Verified_year_details from "./module/Verified_business_idea/Verified_year_details";
import PostParticpientDeatils from "./module/PostRegistration/PostParticpientDeatils";
// import PostParticipentDetails from './module/PostRegistration/PostParticipentDetails';
// import PostParticipentDetails from './module/PostRegistration/PostParticipentDetails';
//import VerifiedBussinessIdeaTable from './module/Verified_business_idea/VerifiedBussinessIdeaTable';

function App() {
  return (
    <>
      <AuthRoute />
      <Routes>
        <Route path="/expire" element={<Unauthorized />} />
        <Route path="/" element={<Loginpage />} />
        <Route element={<ProtectedLayout />}>
          {/* <Route path="Dashbored" element={<Dashbored />} exact />
          <Route path="Awards" element={<Awards />} >

          </Route>
          <Route path="EventAward/:id" element={<EventAward/>} exact />
          <Route path="Registration" element={<Registration />} exact />
          <Route path="RoleAccess" element={<RoleAccess />} exact />
          <Route path="Verified_business_idea" element={<Verified_business_idea />} exact />


          <Route path="/PreRegistration" breadcrumb="Registration" element={<PreRegistration />} exact />
          <Route path="/PostRegistration" breadcrumb="PostRegistration" element={<PostRegistration />} />
          <Route path="/ParticipentDetails" breadcrumb="ParticipentDetails" element={<ParticipentDetails />} />
          <Route path='/Buttons' breadcrumb="Buttons" element={<Buttons />} /> */}

          <Route path="dashbored" element={<Dashbored />} exact />
          <Route path="awards" element={<Awards />} />
          <Route path="awards/:id/:name" element={<EventAward />} exact />
          <Route
            path="awards/registration/:id/:name"
            element={<Registration />}
            exact
          />
          <Route
            path="awards/preregistration/:id/:name"
            breadcrumb="Registration"
            element={<PreRegistration />}
          />
          <Route
            path="awards/postregistration/:id/:name"
            breadcrumb="PostRegistration"
            element={<PostRegistration />}
          />
          <Route
            path="/participentDetails/:id"
            breadcrumb="ParticipentDetails"
            element={<ParticipentDetails />}
          />
          <Route path="roleAccess" element={<RoleAccess />} exact />

          <Route
            path="/postParticpientDeatils/:id"
            element={<PostParticpientDeatils />}
          />

          <Route
            path="verifiedbusinessidea"
            element={<Verified_business_idea />}
            exact
          />
          <Route
            path="/verifiedbusinessidea/verified_year_details/:id/:name"
            element={<Verified_year_details />}
            exact
          />
          <Route
            path="/verifiedbusinessidea/verified_bussinessIdea_table/:id/:name"
            element={<VerifiedBussinessIdeaTable />}
            exact
          />

          {/* <Route path='PostParticipentDetails' element={<PostParticipentDetails/>}/> */}
        </Route>
      </Routes>
    </>
    // <MainComponent/>
  );
}

export default App;
