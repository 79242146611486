import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getRequest, patchRequest, postRequest } from "../../../utility/commonServices";
import { APIROUTES } from "../../../routes/apiRoutes";
import { GET_PARTICIEPENTDEATILS, PATCH_ROLLACCESS, POST_CREATE_NEW_PROJECT } from "../../../redux/actionTypes";

export const getParticipentDetailsMiddleware = createAsyncThunk(
    GET_PARTICIEPENTDEATILS,
    async ({ id }, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(`${APIROUTES.PARTICPENTDEATILS.GET_PARTICIEPENTDEATILS}${id}`);
            console.log(data);
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
export const getPostParticipentDetailsMiddleWare = createAsyncThunk(
    GET_PARTICIEPENTDEATILS,
    async ({ id }, { rejectWithValue }) => {
        try {
            const { data } = await getRequest(`${APIROUTES.PARTICPENTDEATILS.GET_PARTICIEPENTDEATILS}${id}`);
            console.log(data);
            return data;
        } catch (error) {
            if (error.response?.data?.error?.message) {
                //   Toast.danger({message: error.response.data.error.message});
            }
            // const typedError = error as Error;
            return rejectWithValue(error.response?.data?.error?.message);
        }
    },
);
// export const getPostParticipentDetailsMiddleWare=
// export const postPreRegistrationMiddleWare = createAsyncThunk(
//     POST_CREATE_NEW_PROJECT,
//     async (payload, { rejectWithValue }) => {
//         try {
//             const { data } = await postRequest(APIROUTES.PREREGISTRATION.POST_CREATE_NEW_PROJECT,payload);
//             console.log("======>postData",data);
//             return data;
//         } catch (error) {
//             if (error.response?.data?.error?.message) {
//                 //   Toast.danger({message: error.response.data.error.message});
//             }
//             // const typedError = error as Error;
//             return rejectWithValue(error.response?.data?.error?.message);
//         }
//     },
// );

// export const patchUserMiddleWare = createAsyncThunk(
//     PATCH_ROLLACCESS,
//     async (payload, { rejectWithValue }) => {
//         try {
//             const { data } = await patchRequest(`${APIROUTES.ROLLACCESSUSER.PATCH_USERDATA}${payload.id}`, payload);
//             return data;
//         } catch (error) {
//             if (error.response?.data?.error?.message) {
//                 //   Toast.danger({message: error.response.data.error.message});
//             }
//             // const typedError = error as Error;
//             return rejectWithValue(error.response?.data?.error?.message);
//         }
//     },
// );
