import { createSlice } from "@reduxjs/toolkit";

import {
  getPreRegistationMiddleWare,
  postPreRegistrationMiddleWare,
  patchGetPreMiddleWare,
  deletePreMiddleWare,
  getPreGetIndividualMiddleWare,
} from "./preRegistrationMiddleware.";

import { getSearchMiddleWare } from "./getPreSearchMiddleWare";

const initialState = {
  loading: false,

  error: "",

  preRegData: [],

  user: {},

  pageNo: 1,

  count: 0,
};

const preRegistrationReducers = createSlice({
  name: "preRegistration",

  initialState,

  reducers: {
    setSkip: (state, action) => {
      state.pageNo = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSearchMiddleWare.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSearchMiddleWare.fulfilled, (state, action) => {
     

      state.loading = false;

      state.preRegData = action?.payload;

      state.count = action?.payload?.allProjectCount;
    });

    builder.addCase(getSearchMiddleWare.rejected, (state, action) => {
    
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });

    builder.addCase(getPreRegistationMiddleWare.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getPreRegistationMiddleWare.fulfilled, (state, action) => {
    
      state.loading = false;

      state.preRegData = action?.payload;

      state.count = action?.payload?.allProjectCount;

    });

    builder.addCase(getPreRegistationMiddleWare.rejected, (state, action) => {
     
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });

    builder.addCase(getPreGetIndividualMiddleWare.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getPreGetIndividualMiddleWare.fulfilled,
      (state, action) => {
       
        state.loading = false;

        state.user = action?.payload;

        state.count = action?.payload?.allProjectCount;
      }
    );

    builder.addCase(getPreGetIndividualMiddleWare.rejected, (state, action) => {
    
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });

    builder.addCase(
      postPreRegistrationMiddleWare.fulfilled,
      (state, action) => {
      
        state.loading = false;

        state.user = action?.payload;
      }
    );

    builder.addCase(postPreRegistrationMiddleWare.rejected, (state, action) => {
    
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });

    builder.addCase(postPreRegistrationMiddleWare.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(patchGetPreMiddleWare.fulfilled, (state, action) => {
   
      state.loading = false;

      state.user = action?.payload;
    });

    builder.addCase(patchGetPreMiddleWare.rejected, (state, action) => {
    
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(patchGetPreMiddleWare.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePreMiddleWare.fulfilled, (state, action) => {
     state.loading = false;

      state.user = action?.payload;
    });

    builder.addCase(deletePreMiddleWare.rejected, (state, action) => {
      state.loading = false;

      state.error = typeof action.payload === "string" ? action.payload : "";
    });
    builder.addCase(deletePreMiddleWare.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { setSkip } = preRegistrationReducers.actions;

export default preRegistrationReducers.reducer;
