import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import "../PostRegistration/PostParticpientDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPostParticipentDetails } from "../PostRegistration/store/postRegisterMiddleware";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const PostParticpientDeatils = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = useParams();
  const handleSubmit = () => {
    navigate(-1);
  };
  const { data, loading } = useSelector(({ postParticipentDetails }) => {
    return {
      loading: postParticipentDetails?.loading,
      data: postParticipentDetails?.preRegData,
    };
  });
  const handlegetPreRegister = () => {
    dispatch(getPostParticipentDetails({ id }));
  };
  useEffect(() => {
    handlegetPreRegister();
  }, []);

  console.log(data, "---------------->data");

  return (
    <div>
      <p className="ParticipentText">Participant Details </p>
      <div
        className="card justify-content-center align-items-center align-self-center"
        style={{ marginTop: 20 }}
      >
        <div className="Form">
          <label className="lable1">Registration No:</label>
          <div>
            <InputText
              className="textInput"
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.registrationNo}
            />
          </div>
          <label className="lable2">Project Name :</label>
          <div>
            <InputText
              className="projectInputbox"
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.projectName}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent1 === true ? "lable1" : "labledesable"
            }
          >
            Name :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent1 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent1}
            />
          </div>

          <label
            className={
              data?.attatenceStudent1 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent1 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent1}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent1 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent1 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent1}
            />
          </div>

          <label
            className={
              data?.attatenceStudent1 === true ? "lable2" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent1 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent1}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent2 === true ? "lable1" : "labledesable"
            }
          >
            Name1 :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent2 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent2}
            />
          </div>

          <label
            className={
              data?.attatenceStudent2 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent2 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent2}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent2 === true ? "lable2" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent2 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent2}
            />
          </div>

          <label
            className={
              data?.attatenceStudent2 === true ? "lable2" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent2 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent2}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            Name2 :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent3 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent3}
            />
          </div>

          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent3 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent3}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent3 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent3}
            />
          </div>

          <label
            className={
              data?.attatenceStudent3 === true ? "lable2" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent3 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent3}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent4 === true ? "lable2" : "labledesable"
            }
          >
            Name3 :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent4 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.nameStudent4}
            />
          </div>

          <label
            className={
              data?.attatenceStudent4 === true ? "lable2" : "labledesable"
            }
          >
            Email Id :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent4 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.emailStudent4}
            />
          </div>
        </div>
        <div className="Form">
          <label
            className={
              data?.attatenceStudent4 === true ? "lable1" : "labledesable"
            }
          >
            Conatct No:
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent4 === true
                  ? "textInput"
                  : "textInputdisable"
              }
              onChange={(e) =>
                handleInputChange("Registration No", e.target.value)
              }
              value={data?.mobileStudent4}
            />
          </div>

          <label
            className={
              data?.attatenceStudent4 === true ? "lable1" : "labledesable"
            }
          >
            College :
          </label>
          <div>
            <InputText
              className={
                data?.attatenceStudent4 === true
                  ? "projectInputbox"
                  : "projectInputboxdisable"
              }
              onChange={(e) =>
                handleInputChange("Project Name", e.target.value)
              }
              value={data?.collegeStudent4}
            />
          </div>
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <label className="projectSummaryText">projectSummary :</label>
          <div style={{ marginTop: 15 }}>
            <InputTextarea
              autoResize
              rows={5}
              cols={30}
              style={{
                width: 1020,
                height: 150,
                backgroundColor: "#f6f6f6",
                borderStyle: "none",
              }}
              value={data?.projectSummary}
            />
          </div>
        </div>
        <div className="responsibleView">
          <label className="label3">Responsible person Name :</label>
          <InputText
            className="inputField"
            onChange={(e) =>
              handleInputChange("Responsible person Name", e.target.value)
            }
            value={data?.responsivePersonName}
          />
          <label className="label3">Responsible person Contact :</label>
          <InputText
            className="inputField"
            onChange={(e) =>
              handleInputChange("Responsible person Contact", e.target.value)
            }
            value={data?.responsivePersonContactNo}
          />
        </div>
      </div>
      <div className="ButtonHead">
        <Button onClick={handleSubmit} className="submit" label="Back"></Button>
      </div>
    </div>
  );
};

export default PostParticpientDeatils;
